export function ShopifyIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="62" height="18" fill="none">
      <path
        d="M7.02.56c.124 0 .186.062.311.124-.745.311-1.491 1.118-1.864 2.858L4.1 3.915C4.535 2.672 5.405.56 7.02.56zm.683.621a4.33 4.33 0 0 1 .248 1.491v.124l-1.802.497c.373-1.305.994-1.864 1.553-2.112zM9.319 2.3l-.808.248v-.186c0-.559-.062-.994-.186-1.367.435.124.808.683.994 1.305zm4.038.994c0-.062-.062-.124-.124-.124-.124 0-1.429-.124-1.429-.124l-1.056-.994c-.124-.124-.311-.062-.373-.062l-.497.186c-.311-.87-.87-1.677-1.802-1.677h-.062C7.766.125 7.393.001 7.082.001 4.846-.061 3.79 2.734 3.417 4.163c-.497.124-.994.311-1.553.497-.497.124-.497.186-.559.621C1.242 5.592 0 15.408 0 15.408l9.878 1.864 5.343-1.18-1.864-12.798z"
        fill="#8db849"
      />
      <path
        d="M13.17 3.169c-.062 0-1.367-.124-1.367-.124l-1.056-.994c-.062-.062-.062-.062-.124-.062l-.746 15.283 5.343-1.18-1.864-12.798c0-.062-.124-.124-.186-.124z"
        fill="#5a863e"
      />
      <path
        d="M8.076 6.151l-.683 1.988s-.559-.311-1.305-.311c-1.056 0-1.118.621-1.118.808 0 .87 2.361 1.242 2.361 3.355 0 1.677-1.056 2.733-2.485 2.733-1.677 0-2.547-1.056-2.547-1.056l.435-1.491s.87.745 1.615.745c.497 0 .683-.373.683-.683 0-1.18-1.926-1.242-1.926-3.168 0-1.615 1.18-3.168 3.479-3.168 1.056-.062 1.491.248 1.491.248z"
        fill="#fffffe"
      />
      <path
        d="M21.495 9.817c-.559-.311-.808-.559-.808-.87 0-.435.373-.683.994-.683.683 0 1.305.311 1.305.311l.497-1.491s-.435-.373-1.739-.373c-1.864 0-3.106 1.056-3.106 2.547 0 .87.621 1.491 1.367 1.926.621.373.87.621.87.994s-.311.745-.932.745c-.87 0-1.739-.435-1.739-.435l-.497 1.491s.745.497 2.05.497c1.864 0 3.23-.932 3.23-2.609 0-.932-.683-1.615-1.491-2.05zm7.517-3.168c-.932 0-1.677.435-2.236 1.118l.808-4.224h-2.112l-2.05 10.748h2.112l.683-3.665c.248-1.367.994-2.237 1.677-2.237.497 0 .683.311.683.808 0 .311 0 .621-.062.932l-.808 4.224h2.112l.808-4.349c.062-.435.124-.994.124-1.367 0-1.242-.621-1.988-1.739-1.988zm5.405 6.15c-.745 0-.994-.621-.994-1.367 0-1.18.621-3.168 1.739-3.168.745 0 .994.621.994 1.242 0 1.367-.621 3.293-1.739 3.293zm1.056-6.15c-2.547 0-4.224 2.299-4.224 4.846 0 1.615.994 2.92 2.92 2.92 2.485 0 4.162-2.236 4.162-4.846 0-1.491-.87-2.92-2.858-2.92zm6.212 6.212c-.559 0-.87-.311-.87-.311l.373-1.988c.248-1.305.932-2.174 1.677-2.174.621 0 .87.621.87 1.18-.062 1.367-.87 3.293-2.05 3.293zm2.05-6.212c-1.429 0-2.236 1.242-2.236 1.242l.124-1.118H39.76c-.062.745-.248 1.926-.435 2.796l-1.491 7.703h2.112l.559-3.106h.062s.435.248 1.242.248c2.485 0 4.1-2.547 4.1-5.094 0-1.367-.621-2.671-2.174-2.671zm5.156-2.982c-.683 0-1.18.559-1.18 1.242 0 .621.373 1.056.994 1.056.683 0 1.242-.435 1.242-1.242 0-.621-.435-1.056-1.056-1.056zm-2.92 10.623h2.112l1.429-7.455h-2.112l-1.429 7.455zm8.884-7.455h-1.491l.062-.373c.124-.745.559-1.367 1.242-1.367a2.05 2.05 0 0 1 .683.124l.435-1.677s-.373-.186-1.118-.186-1.491.186-2.05.683c-.745.621-1.056 1.491-1.242 2.361l-.062.373h-.994l-.311 1.615h.994l-1.118 5.902h2.112l1.118-5.902h1.429l.311-1.553zm5.032 0l-1.926 5.094-.497-5.094h-2.236l1.242 6.834c0 .124 0 .248-.062.373-.248.497-.683.932-1.118 1.242-.373.311-.87.497-1.18.621l.559 1.802c.435-.062 1.305-.435 2.05-1.118.932-.87 1.864-2.299 2.733-4.162L62 6.897l-2.112-.062z"
        fill="#1a1919"
      />
    </svg>
  );
}
