import { useCallback, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useNavigate } from "react-router-dom";
import { Merchant } from "src/api/types";
import useAuthorizedApi from "src/helpers/hooks/app/useAuthorizedApi";
import {
  ALLOWED_SCOLLECTION_TYPES_FOR_EDITING,
  useListingEditorContext,
} from "../../contexts/ListingTreeSelectorContext";
import {
  useCreateSCollectionMutation,
  useDeleteCategoryCB,
} from "../../helpers/categories/categoryV3.helper";
import { getNextListingName } from "../../helpers/categories/getNextListingName";
import useListingItems from "../../helpers/hooks/useListingItems";
import useScollections from "../../helpers/hooks/useScollections";
import { OnAddListingItem } from "../navs/types";
import ListingTreeMenu from "../storybook/ListingTreeMenu/ListingTreeMenu";
import { useProductScollectionContext } from "../../contexts/ProductScollectionContext";
import ListingTreeContextMenu from "./ListingTreeContextMenu";
import { ListingTreeContextMenuState } from "./types";
import { Button, Modal } from "react-bootstrap";
import { ListingItem } from "../storybook/ListingTreeMenu/types";
import { css } from "@emotion/react";

export default function ListingTree({
  merchant,
}: {
  merchant: Merchant | null;
}) {
  const {
    listingItems,
    isLoading: listingItemsAreLoading,
    currentListingItemId,
    refetch,
  } = useListingItems();

  const { onUpdateListingType, onUpdateParentId } = useScollections();

  const { updateProductToScollections: onUpdateListingMainProducts } =
    useProductScollectionContext();

  const {
    inSelectMode,
    selectedListingItemIds,
    indeterminateListingItemIds,
    selectedMainProductIds,
    disabledListingItemIds,
  } = useListingEditorContext(merchant?.id);

  const navigate = useNavigate();

  const { api } = useAuthorizedApi();

  const createScollectionMutation = useCreateSCollectionMutation(
    api,
    merchant?.id ?? ""
  );

  const onAddListingItem = useCallback<OnAddListingItem>(
    async (type) => {
      if (type === "campaign") {
        const returned = await createScollectionMutation.mutateAsync({
          title: getNextListingName(listingItems, "campaign"),
          type: "campaign",
        });
        const categoryId = returned?.collection_id;
        navigate(`/${merchant?.id}/categories-v3/${categoryId}`);
        await refetch();
      } else if (type === "category") {
        const returned = await createScollectionMutation.mutateAsync({
          title: getNextListingName(listingItems, "category"),
          type: "category",
        });

        const categoryId = returned?.collection_id;

        if (!categoryId) {
          throw new Error("Category ID is null, should never happen");
        }

        navigate(`/${merchant?.id}/categories-v3/${categoryId}`);
        await refetch();
      }
    },
    [createScollectionMutation, listingItems, merchant?.id, navigate, refetch]
  );

  const [listingContextMenuState, setListingContextMenuState] =
    useState<ListingTreeContextMenuState | null>(null);

  const [deleteListingItemStaged, setDeleteListingItemStaged] =
    useState<ListingItem | null>(null);

  const deleteListingItem = useDeleteCategoryCB(api);

  return (
    <DndProvider backend={HTML5Backend}>
      <ListingTreeMenu
        contextMenuState={listingContextMenuState}
        disabledListingItemIds={disabledListingItemIds}
        onSetListingType={(listingType, listingItemId) =>
          onUpdateListingType(listingItemId, listingType)
        }
        editableListingTypes={ALLOWED_SCOLLECTION_TYPES_FOR_EDITING}
        onSetParentId={(listingItemId, parentId) =>
          onUpdateParentId(listingItemId, parentId)
        }
        isLoading={listingItemsAreLoading}
        listingItems={listingItems ?? []}
        currentListingItemId={currentListingItemId ?? undefined}
        onAddListingItem={onAddListingItem}
        inSelectMode={inSelectMode}
        selectedListingItemIds={selectedListingItemIds}
        onChangeSelectedListingItemIds={(checked, listingItemIds) => {
          onUpdateListingMainProducts({
            listingItemIds,
            mainProductIds: selectedMainProductIds,
            remove: !checked,
          });
        }}
        indeterminateListingItemIds={indeterminateListingItemIds}
        onSetContextMenuState={setListingContextMenuState}
      />
      {listingContextMenuState && (
        <ListingTreeContextMenu
          onSetDeleteListingItemStaged={setDeleteListingItemStaged}
          editableListingTypes={ALLOWED_SCOLLECTION_TYPES_FOR_EDITING}
          onMoveTo={(listingItemId, targetListingItemId) => {
            if (listingItemId === targetListingItemId) return;
            onUpdateParentId(listingItemId, targetListingItemId);
          }}
          listingItems={listingItems ?? []}
          listingContextMenuState={listingContextMenuState}
          setListingContextMenuState={setListingContextMenuState}
        />
      )}
      <Modal
        show={deleteListingItemStaged !== null}
        onHide={() => setDeleteListingItemStaged(null)}
        className="depict--Modal"
        css={css`
          .modal-content {
            padding: 32px;
            max-width: 502px;
          }
          .modal-dialog {
            max-width: 502px;
          }
        `}
      >
        <h1
          css={css`
            color: #000;
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 24px;
          `}
        >
          Delete collection
        </h1>
        <div
          css={css`
            display: flex;
            gap: 16px;
            justify-content: space-between;
          `}
        >
          <div
            css={css`
              color: #92363e;
              font-size: 12px;
              font-weight: 400;
            `}
          >
            <strong>Are you sure?</strong>
            <br />
            <span>
              This will delete the merchandising data associated with this list
              (e.g. pins).
            </span>
          </div>
          <Button
            variant="danger"
            onClick={() => {
              if (!merchant || !deleteListingItemStaged) return;
              deleteListingItem(merchant.id, deleteListingItemStaged.id).then(
                () => setDeleteListingItemStaged(null)
              );
            }}
            css={css`
              white-space: nowrap;
            `}
          >
            Yes, delete anyway
          </Button>
        </div>
      </Modal>
    </DndProvider>
  );
}
