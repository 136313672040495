import { css } from "@emotion/react";
import { List, X } from "@phosphor-icons/react";
import React from "react";
import { Button } from "react-bootstrap";
import "src/assets/scss/depict/layouts/sidebar.scss";
import SidebarNav, { SmallScreenCutOff } from "src/components/navs/SidebarNav";
import { useMerchant } from "src/helpers/hooks/app/useMerchant";
import { SidebarLayoutProps } from "src/types/components";
import { theme } from "../components/storybook/designSystemVariables";
import { ListingEditorProvider } from "../contexts/ListingTreeSelectorContext";
import useProductInbox from "../helpers/hooks/useProductInbox";
import useUser from "src/helpers/hooks/useUser";
import { ProductScollectionProvider } from "../contexts/ProductScollectionContext";
import { ImageResizeProvider } from "../components/storybook/ImageResizeProvider/ImageResizeProvider";
import SyncBannerWrapper from "../components/storybook/Alert/SyncBannerWrapper/SyncBannerWrapper";
import { useSyncBanner } from "../components/storybook/Alert/SyncBannerWrapper/SyncBannerContext";

export const mainId = "main";

const SidenavLayout = (props: SidebarLayoutProps) => {
  const { merchant } = useMerchant();

  const { user } = useUser();

  const { children } = props;

  const { inboxMainProductIds } = useProductInbox(merchant?.id ?? null);
  const [sidebarOpen, setSidebarOpen] = React.useState(true);

  const { showSyncBanner } = useSyncBanner();

  return (
    <ImageResizeProvider merchantId={merchant?.id}>
      <ProductScollectionProvider merchantId={merchant?.id}>
        <ListingEditorProvider merchantId={merchant?.id}>
          {showSyncBanner && <SyncBannerWrapper />}
          <div
            className="p-2 line-height-normal"
            css={css`
              background: ${theme.background.base.hover};
              @media (min-width: ${SmallScreenCutOff}px) {
                ${sidebarOpen && "display: none;"}
              }
            `}
          >
            <Button
              variant="secondary"
              className="p-1"
              css={css`
                background: transparent;
                border: none;
                margin-left: 13px;
              `}
              onClick={() => {
                setSidebarOpen(!sidebarOpen);
              }}
            >
              {sidebarOpen ? (
                <X size={16} color={"#000000"} />
              ) : (
                <List size={16} color={"#000000"} />
              )}
            </Button>
          </div>
          <div
            css={css`
              display: flex;
              overflow: hidden;
              background: ${theme.background.base.hover};
              height: calc(100vh - 40px);
              @media (min-width: ${SmallScreenCutOff}px) {
                ${sidebarOpen && "height: 100vh;"}
              }
            `}
          >
            {sidebarOpen && (
              <SidebarNav
                inboxCount={inboxMainProductIds?.length ?? null}
                merchant={merchant ?? undefined}
                user={user ?? undefined}
              />
            )}
            <main
              id={mainId}
              css={css`
                flex: 1;
                overflow-x: hidden;
                display: flex;
                flex-direction: column;
              `}
            >
              {children}
            </main>
          </div>
        </ListingEditorProvider>
      </ProductScollectionProvider>
    </ImageResizeProvider>
  );
};

export default React.memo(SidenavLayout);
