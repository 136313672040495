import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import { shopifyToken } from "../../misc/shopifyToken";
import config from "src/config";
import { useMaybeAppBridge } from "src/helpers/shopify/useMaybeAppBridge";

export default function useAuthentication(): [
  getAccessToken: () => Promise<string>,
] {
  const { getAccessTokenSilently } = useAuth0();
  const appBridge = useMaybeAppBridge();

  const getAccessToken = useCallback(async () => {
    if (shopifyToken) {
      return shopifyToken;
    }
    if (appBridge?.environment.embedded) {
      return await appBridge.idToken();
    }
    if (config.useLocalStorageAccessToken) {
      //Check if local storage has a token and use that instead
      const token = localStorage.getItem("prod_token");
      if (token) {
        return token;
      }
    }
    const { id_token } = await getAccessTokenSilently({
      detailedResponse: true,
    });
    return id_token;
  }, [getAccessTokenSilently, appBridge]);

  return [getAccessToken];
}
