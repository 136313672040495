import { createContext, useContext, useState } from "react";
import { throwErrorIfAny } from "../api/authorizedApi";
import { useMutation } from "@tanstack/react-query";
import useAuthorizedApi from "../helpers/hooks/app/useAuthorizedApi";

interface ProductScollectionContextData {
  productToScollections: Map<string, string[]>;
  productToRuleBasedScollections: Map<string, string[]>;
  updateProductToScollections: (params: {
    listingItemIds: Set<string>;
    mainProductIds: Set<string>;
    remove: boolean;
  }) => void;
  fetchProductToScollections: (mainProductIds: string[]) => Promise<void>;
}

const ProductScollectionContext = createContext<ProductScollectionContextData>({
  productToScollections: new Map(),
  productToRuleBasedScollections: new Map(),
  updateProductToScollections: () => {},
  fetchProductToScollections: async () => {},
});

export function ProductScollectionProvider({
  merchantId,
  children,
}: {
  merchantId?: string;
  children: React.ReactNode;
}) {
  const [productToScollections, setProductToScollections] = useState<
    Map<string, string[]>
  >(new Map());
  const [productToRuleBasedScollections, setProductToRuleBasedScollections] =
    useState<Map<string, string[]>>(new Map());

  const { api } = useAuthorizedApi();

  const fetchProductToScollections = async (mainProductIds: string[]) => {
    if (!merchantId || !api) return;

    const response = await api.POST(
      "/api/v0/listing/{merchant_id}/scollections/product-scollections",
      {
        params: {
          path: {
            merchant_id: merchantId,
          },
        },
        body: mainProductIds,
      }
    );

    throwErrorIfAny(response);

    if (response.data) {
      setProductToScollections((prev) => {
        const next = new Map(prev);
        for (const mapping of response.data) {
          next.set(mapping.main_product_id, mapping.collection_ids);
        }
        return next;
      });
      setProductToRuleBasedScollections((prev) => {
        const next = new Map(prev);
        for (const mapping of response.data) {
          next.set(mapping.main_product_id, mapping.rule_based_collection_ids);
        }
        return next;
      });
    }
  };

  const { mutate: updateListingMainProducts } = useMutation({
    mutationFn: async ({
      listingItemIds,
      mainProductIds,
      remove,
    }: {
      listingItemIds: Set<string>;
      mainProductIds: Set<string>;
      remove: boolean;
    }) => {
      if (!merchantId || !api) return;

      setProductToScollections((prev) => {
        const newProductToScollections = new Map(prev);
        for (const mainProductId of mainProductIds) {
          const collectionIds = newProductToScollections.get(mainProductId);
          if (collectionIds) {
            if (remove) {
              newProductToScollections.set(
                mainProductId,
                collectionIds.filter((id) => !listingItemIds.has(id))
              );
            } else {
              newProductToScollections.set(
                mainProductId,
                Array.from(
                  new Set([...collectionIds, ...listingItemIds.values()])
                )
              );
            }
          } else {
            if (!remove) {
              newProductToScollections.set(
                mainProductId,
                Array.from(listingItemIds.values())
              );
            }
          }
        }
        return newProductToScollections;
      });

      const response = await api.PUT(
        "/api/v0/listing/{merchant_id}/scollections/products",
        {
          params: {
            path: {
              merchant_id: merchantId,
            },
          },
          body: {
            collection_ids: Array.from(listingItemIds),
            main_product_ids: Array.from(mainProductIds),
            remove,
          },
        }
      );

      throwErrorIfAny(response);
    },
  });

  return (
    <ProductScollectionContext.Provider
      value={{
        productToScollections,
        updateProductToScollections: ({
          listingItemIds,
          mainProductIds,
          remove,
        }) => {
          updateListingMainProducts({
            listingItemIds,
            mainProductIds,
            remove,
          });
        },
        fetchProductToScollections,
        productToRuleBasedScollections,
      }}
    >
      {children}
    </ProductScollectionContext.Provider>
  );
}

export function useProductScollectionContext(merchantId?: string) {
  const context = useContext(ProductScollectionContext);
  if (!context) {
    throw new Error(
      "useProductScollectionContext must be used within a ProductScollectionProvider"
    );
  }

  const {
    productToScollections,
    updateProductToScollections,
    fetchProductToScollections,
    productToRuleBasedScollections,
  } = context;

  return {
    productToScollections,
    updateProductToScollections,
    fetchProductToScollections,
    productToRuleBasedScollections,
  };
}
