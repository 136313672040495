import { useMutation, useQuery } from "@tanstack/react-query";
import { getData, throwErrorIfAny } from "src/api/authorizedApi";
import { CollectionListDto, CollectionType } from "src/api/types";
import { useAlerts } from "../../components/storybook/Alert/useAlerts";
import queryClient from "../../queries/client";
import { QueryId, getQueryKey } from "../../queries/queries";
import useAuthorizedApi from "./app/useAuthorizedApi";
import useMerchant from "./app/useMerchant";

const useScollections = () => {
  const { merchant } = useMerchant();

  const { api } = useAuthorizedApi();

  const listingActivated =
    merchant?.product_status?.plp_state === "live" ||
    merchant?.product_status?.plp_state === "in_development";

  const enabled = !!merchant && !!api && listingActivated;

  const queryKey = getQueryKey(
    QueryId.GetSCollections,
    merchant?.id || "",
    true
  );

  const {
    data: scollections,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey,
    queryFn: async () => {
      if (!merchant || !api || !listingActivated) return;
      const response = await api.GET(
        "/api/v0/listing/{merchant_id}/scollections/",
        {
          params: {
            path: {
              merchant_id: merchant.id,
            },
          },
        }
      );

      return getData(response);
    },
    enabled,
    staleTime: Infinity,
  });

  const { addAlert } = useAlerts();

  const { mutate: updateParentId, isError: parentUpdateIsError } = useMutation({
    mutationFn: async ({
      listingItemId,
      parentId,
    }: {
      listingItemId: string;
      parentId: string | null;
    }) => {
      if (!merchant || !api) return;

      queryClient.setQueryData<CollectionListDto[] | undefined>(
        queryKey,
        (prev) => {
          if (!prev) return prev;
          const listingItem = prev.find(
            (item) => item.collection_id === listingItemId
          );
          if (!listingItem) {
            return prev;
          }
          return [
            ...prev.filter((item) => item.collection_id !== listingItemId),
            {
              ...listingItem,
              parent_id: parentId ?? undefined,
            },
          ];
        }
      );

      const response = await api.PUT(
        "/api/v0/listing/{merchant_id}/scollections/{collection_id}/update-parent",
        {
          params: {
            path: {
              merchant_id: merchant.id,
              collection_id: listingItemId,
            },
          },
          body: {
            parent_collection_id: parentId ?? null,
          },
        }
      );

      throwErrorIfAny(response);
    },
    onError: async () => {
      addAlert({
        message:
          "Error updating category hierarchy, contact depict for more details",
        type: "danger",
        autohide: true,
        id: "creating-collection-error",
      });
      await refetch();
    },
  });

  const {
    mutate: updateListingType,
    isError: listingTypeUpdateIsError,
    isPending: listingTypeUpdateIsLoading,
  } = useMutation({
    mutationFn: async ({
      listingItemId,
      collectionType,
    }: {
      listingItemId: string;
      collectionType: CollectionType;
    }) => {
      if (!merchant || !api) return;

      const response = await api.PUT(
        "/api/v0/listing/{merchant_id}/scollections/{collection_id}/update-type",
        {
          params: {
            path: {
              merchant_id: merchant.id,
              collection_id: listingItemId,
            },
            query: {
              collection_type: collectionType,
            },
          },
        }
      );

      throwErrorIfAny(response);

      await queryClient.invalidateQueries({
        queryKey,
      });
    },
  });

  return {
    scollections,
    onUpdateParentId: (listingItemId: string, parentId: string | null) => {
      updateParentId({
        listingItemId,
        parentId,
      });
    },
    onUpdateListingType: (
      listingItemId: string,
      collectionType: CollectionType
    ) => {
      updateListingType({
        listingItemId,
        collectionType,
      });
    },
    isLoading: enabled ? isLoading || listingTypeUpdateIsLoading : false,
    isError: isError || parentUpdateIsError || listingTypeUpdateIsError,
    refetch,
  };
};

export default useScollections;
