import {
  instant_exec_on_suspect_history_change,
  catchify,
} from "@depict-ai/utilishared/latest";
import {
  shopifyToken,
  shopifyTokenFromURL,
  shopifyTokenFromWindow,
} from "./shopifyToken";
import {
  preserve_items_in_history_dot_state,
  prefixes_to_preserve_in_history_dot_state,
} from "@depict-ai/ui/latest";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

/**
 * If running in shopify iframe:
 * 1. Adds a click listener to the document that, if a link is to be opened in a new tab or window, opens it using window.open and sets the shopifyToken on the new window.
 * 2. Sends the current location.href to the top frame.
 * If running and an shopify iframe or opened by a shopify iframe:
 * 1. Write the shopifyToken to history.state
 * Used by the shopify app, but we always do it because it doesn't hurt and is easier. (If wwe checked for shopifyToken and only did it then, it might have been removed in a redirect)
 */
export function shopifyIframeIntegration() {
  if (!shopifyToken) return;
  const postLocation = () =>
    window.parent?.postMessage(
      // eslint-disable-next-line no-restricted-globals
      { type: "portal_location_href", href: location.href },
      "*"
    );
  instant_exec_on_suspect_history_change.add(postLocation);
  postLocation();

  // eslint-disable-next-line no-restricted-globals
  addEventListener(
    "click",
    catchify((evt) => {
      let target = evt.target;
      if (!(target instanceof HTMLElement)) return;
      if (!(target instanceof HTMLAnchorElement)) {
        target = target.closest("a");
      }
      if (!target) return;
      const targetHref = (target as HTMLAnchorElement).href;
      // If URL goes to another origin, don't do anything
      // eslint-disable-next-line no-restricted-globals
      if (new URL(targetHref, location.origin).origin !== location.origin)
        return;
      const shouldOpenInNewTab = evt.button || evt.metaKey || evt.ctrlKey;
      const shouldOpenInNewWindow = evt.shiftKey;
      if (shouldOpenInNewTab || shouldOpenInNewWindow) {
        evt.preventDefault();
        evt.stopImmediatePropagation();
        // Seems like Chrome automatically chooses new window here if shift is pressed so we don't have to do compromises like setting popup=true
        const newContext = window.open(targetHref, "_blank");
        (newContext as any).shopifyToken = shopifyToken;
        console.log(
          "Opened new window manually, setting shopifyToken on it",
          newContext
        );
      }
    }),
    { capture: true }
  );

  // We got a token, either from the parent frame sending it in via the URL or from a tab opening us in new tab sending it in in the window
  // Save it in history.state so it perseveres across the tab being closed and then re-opened with cmd+shift+t
  if (shopifyTokenFromURL || shopifyTokenFromWindow) {
    // Prevent react from overriding history.state.shopifyToken on subsequent navigations
    prefixes_to_preserve_in_history_dot_state.add("shopifyToken");
    preserve_items_in_history_dot_state();
    // eslint-disable-next-line no-restricted-globals
    history.replaceState(
      {
        // eslint-disable-next-line no-restricted-globals
        ...(history.state || {}),
        shopifyToken: shopifyTokenFromURL || shopifyTokenFromWindow,
      },
      "", // eslint-disable-next-line no-restricted-globals
      location.href
    );
  }
}

/**
 * Listen for the shopify app posting a message that we should use our SPA router to navigate somewhere, used for getting to the onboarding screen
 */
export function useListenForShopifyAppInducedSPANavigation() {
  const navigate = useNavigate();
  useEffect(() => {
    const handler = catchify(({ data }: MessageEvent) => {
      if (typeof data !== "object" || !data) return;
      if (data.type === "post_url_into_portal") {
        console.log("Navigating portal due to message posted into it", data);
        navigate(data.href, { replace: data.replace });
      }
    });
    // eslint-disable-next-line no-restricted-globals
    addEventListener("message", handler);
    // eslint-disable-next-line no-restricted-globals
    return () => removeEventListener("message", handler);
  }, [navigate]);
}
