/**
 * shopifyToken only when set in the URL at page load
 */
export const shopifyTokenFromURL = new URLSearchParams(
  window.location.search
).get("shopifyToken");

/**
 * shopifyToken only when set on the window object by a tab opening us
 */
export const shopifyTokenFromWindow = (window as any).shopifyToken;

/**
 * shopifyToken set in the URL at page load, or set on the window object by a window that got it set in the URL, when opening this window/tab in a new tab. Or preserved in history.state from a tab being closed and then re-opened with cmd+shift+t.
 * This is the JWT token returned from shopify that proves a user has access to our app.
 */
export const shopifyToken =
  shopifyTokenFromURL ||
  shopifyTokenFromWindow ||
  // eslint-disable-next-line no-restricted-globals
  history.state?.shopifyToken;
