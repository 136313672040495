import { css, SerializedStyles } from "@emotion/react";
import { FC } from "react";
import { theme } from "../designSystemVariables";

type IconButtonColor = "regular" | "black";

type IconButtonProps = {
  icon: JSX.Element;
  color?: IconButtonColor;
  float?: boolean;
  size?: "sm" | "md";
  extraCss?: SerializedStyles;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const getIconColor = (color?: IconButtonColor, disabled?: boolean) => {
  if (disabled) {
    return theme.textIcon.base.disabled;
  } else if (color === "black") {
    return theme.textIcon.inverse.default;
  }

  return theme.textIcon.base.default;
};

export const IconButton: FC<IconButtonProps> = ({
  icon,
  color,
  float,
  disabled,
  size,
  extraCss,
  ...props
}) => (
  <button
    disabled={disabled}
    {...props}
    css={[
      css`
        border: 0;
        background: ${color === "black"
          ? theme.background.inverse.default
          : "none"};
        width: ${size === "sm" ? 1 : 2}rem;
        height: ${size === "sm" ? 1 : 2}rem;
        padding-block: ${size === "sm" ? "1px" : "inherit"};
        padding-inline: ${size === "sm" ? "1px" : "inherit"};
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2.25rem;
        cursor: not-allowed;

        ${float &&
        `
      position: absolute;
      left: -1px;
      top: -1px;
      `}

        ${!disabled &&
        `
      &:hover {
        background: ${
          color === "black"
            ? theme.background.inverse.hover
            : theme.background.subtle.hover
        };
      }
      `}
        
      &.active {
          svg {
            fill: ${color === "black"
              ? theme.textIcon.inverse.pressed
              : theme.textIcon.base.pressed};
          }
        }

        & svg {
          color: ${getIconColor(color, disabled)};
          font-size: 1rem;
        }
      `,
      extraCss,
    ]}
  >
    {icon}
  </button>
);
