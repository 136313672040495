import { useMerchantId } from "./useMerchantId";
import { Merchant } from "src/api/types";
import useOptionalMerchant from "./useOptionalMerchant";

export interface UseMerchantReturn {
  merchant: Merchant | null;
  merchantOptions: Merchant[];
  merchantId: string;
  loading: boolean;
}

/**
 * useMerchant provides access to the selected merchant
 */
export function useMerchant(): UseMerchantReturn {
  const optionalMerchant = useOptionalMerchant();
  const { merchantId } = useMerchantId();

  if (optionalMerchant.loading) {
    return {
      merchant: null,
      merchantOptions: [],
      merchantId,
      loading: true,
    };
  }

  if (!optionalMerchant.merchant) {
    return {
      merchant: null,
      merchantOptions: [],
      merchantId,
      loading: false,
    };
  }

  const { merchant, merchantOptions } = optionalMerchant;

  return {
    merchant,
    merchantOptions,
    merchantId,
    loading: false,
  };
}

export default useMerchant;
