import { css } from "@emotion/react";
import {
  Circle,
  MagnifyingGlass,
  Plus,
  Square,
  SquaresFour,
} from "@phosphor-icons/react";
import { useRef, useState } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import { OnAddListingItem } from "src/components/navs/types";
import useOwnCategories from "src/helpers/hooks/categories_v2_v3_common/useOwnCategories";
import { CollectionType } from "src/api/types";
import { theme } from "../designSystemVariables";
import { ListingSorting } from "./types";
import ListingTreeFilterButtons from "./ListingTreeFilterButtons";
import ListingTreeSortingDropdown from "./ListingTreeSortingDropdown";

export default function ListingTreeMenuHeader({
  searchQuery,
  onSetSearchQuery,
  sorting,
  onSetSorting,
  onAddListingItem,
  filteredType,
  onSetFilteredType,
  availableFilterTypes,
  isLoading,
  onSetType,
  dontShowTitle,
}: {
  dontShowTitle?: boolean;
  searchQuery: string;
  onSetSearchQuery: (value: string) => void;
  sorting: ListingSorting;
  onSetSorting: (value: ListingSorting) => void;
  onAddListingItem?: OnAddListingItem;
  availableFilterTypes: CollectionType[];
  filteredType: CollectionType | null;
  onSetFilteredType: (value: CollectionType | null) => void;
  isLoading?: boolean;
  onSetType: (value: CollectionType, listingItemId: string) => void;
}) {
  const [searchIsFocused, setSearchIsFocused] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const ownCategories = useOwnCategories();

  return (
    <div>
      {!dontShowTitle && (
        <div className="d-flex justify-content-between align-items-center p-2">
          <div className="d-flex align-items-center">
            <SquaresFour size={16} className="me-2" />
            <span
              css={css`
                color: #868689;
                font-weight: bold;
              `}
            >
              Collections
            </span>
          </div>
          {isLoading ? (
            <Spinner
              css={css`
                width: 26px;
                height: 26px;
                margin: 0;
                padding: 0;
              `}
            />
          ) : (
            onAddListingItem && (
              <Dropdown id="add-listing-item">
                <Dropdown.Toggle
                  className="rounded-circle p-1"
                  css={css`
                    text-decoration: none !important;
                    justify-content: flex-start !important;
                    &:after {
                      display: none !important;
                    }
                  `}
                >
                  <Plus size={16} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    disabled={!ownCategories}
                    onClick={() => {
                      onAddListingItem && onAddListingItem("category");
                      onSetFilteredType(
                        filteredType !== "category" ? null : "category"
                      );
                    }}
                  >
                    <Square className="me-2" />
                    Create category
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => {
                      onAddListingItem && onAddListingItem("campaign");
                      onSetFilteredType(
                        filteredType !== "campaign" ? null : "campaign"
                      );
                    }}
                  >
                    <Circle className="me-2" />
                    Create campaign
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )
          )}
        </div>
      )}
      <div className="px-2 d-flex align-items-center">
        <ListingTreeFilterButtons
          availableFilterTypes={availableFilterTypes}
          filteredType={filteredType}
          onSetFilteredType={onSetFilteredType}
          onSetType={onSetType}
        />
      </div>
      <div className="p-2 d-flex justify-content-between align-items-center">
        <div
          className={`d-flex p-1 align-items-center rounded text-xs ${
            searchIsFocused ? "bg-gray-300" : ""
          }`}
          style={{ marginRight: "8px" }}
        >
          {" "}
          <MagnifyingGlass
            width="16px"
            height="16px"
            color={
              searchIsFocused
                ? theme.textIcon.subtle.default
                : theme.textIcon.base.default
            }
            className="me-1"
            onClick={() => {
              if (inputRef.current) {
                inputRef.current.focus();
              }
            }}
          />
          <input
            ref={inputRef}
            className={`border-0 w-100 p-0 ${
              searchIsFocused ? "text-gray-600" : "text-gray-900"
            }`}
            style={{
              minWidth: "0",
              background: "transparent",
              outline: "none",
            }}
            type="text"
            value={searchQuery}
            onChange={(e) => onSetSearchQuery(e.target.value)}
            onFocus={() => setSearchIsFocused(true)}
            onBlur={() => setSearchIsFocused(false)}
            placeholder={!searchIsFocused ? "" : "Search in your collections"}
          />{" "}
        </div>
        <ListingTreeSortingDropdown
          sorting={sorting}
          onSetSorting={onSetSorting}
        />
      </div>
    </div>
  );
}
