import React from "react";

// CSS breakpoints

export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1500,
};

const fontWeight = {
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
};

type TypographyElement = Partial<React.CSSProperties>;

type TypographyWeight = "light" | "heavy";

type Headline = {
  [key in "100" | "200" | "300"]: {
    [key in TypographyWeight]: TypographyElement;
  };
};

type Paragraph = {
  [key in "100" | "200" | "300" | "400"]: {
    [key in TypographyWeight]: TypographyElement;
  };
};

type Button = {
  [key in "100" | "200" | "300"]: {
    [key in "default"]: TypographyElement;
  };
};

type Label = {
  [key in "100" | "200" | "300" | "400"]: {
    [key in "default"]: TypographyElement;
  };
};

type Typography = {
  headline: Headline;
  paragraph: Paragraph;
  button: Button;
  label: Label;
};

const typography: Typography = {
  headline: {
    100: {
      light: {
        fontFamily: "Inter",
        fontWeight: fontWeight.light,
        fontSize: "2rem",
        lineHeight: "2.5rem",
      },
      heavy: {
        fontFamily: "Inter",
        fontWeight: fontWeight.bold,
        fontSize: "2rem",
        lineHeight: "2.5rem",
      },
    },
    200: {
      light: {
        fontFamily: "Inter",
        fontWeight: fontWeight.light,
        fontSize: "1.5rem",
        lineHeight: "1.75rem",
      },
      heavy: {
        fontFamily: "Inter",
        fontWeight: fontWeight.bold,
        fontSize: "1.5rem",
        lineHeight: "1.75rem",
      },
    },
    300: {
      light: {
        fontFamily: "Inter",
        fontWeight: fontWeight.light,
        fontSize: "1.25rem",
        lineHeight: "1.5rem",
      },
      heavy: {
        fontFamily: "Inter",
        fontWeight: fontWeight.bold,
        fontSize: "1.25rem",
        lineHeight: "1.5rem",
      },
    },
  },
  paragraph: {
    100: {
      light: {
        fontFamily: "Inter",
        fontWeight: fontWeight.regular,
        fontSize: "1rem",
        lineHeight: "1.5rem",
      },
      heavy: {
        fontFamily: "Inter",
        fontWeight: fontWeight.bold,
        fontSize: "1rem",
        lineHeight: "1.5rem",
      },
    },
    200: {
      light: {
        fontFamily: "Inter",
        fontWeight: fontWeight.regular,
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
      },
      heavy: {
        fontFamily: "Inter",
        fontWeight: fontWeight.bold,
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
      },
    },
    300: {
      light: {
        fontFamily: "Inter",
        fontWeight: fontWeight.regular,
        fontSize: "0.75rem",
        lineHeight: "1rem",
      },
      heavy: {
        fontFamily: "Inter",
        fontWeight: fontWeight.bold,
        fontSize: "0.75rem",
        lineHeight: "1rem",
      },
    },
    400: {
      light: {
        fontFamily: "Inter",
        fontWeight: fontWeight.regular,
        fontSize: "0.625rem",
        lineHeight: "0.875rem",
      },
      heavy: {
        fontFamily: "Inter",
        fontWeight: fontWeight.bold,
        fontSize: "0.625rem",
        lineHeight: "0.875rem",
      },
    },
  },
  button: {
    100: {
      default: {
        fontFamily: "Inter",
        fontWeight: fontWeight.medium,
        fontSize: "1rem",
        lineHeight: "1.5rem",
        letterSpacing: "0.32px",
      },
    },
    200: {
      default: {
        fontFamily: "Inter",
        fontWeight: fontWeight.medium,
        fontSize: "0.875rem",
        lineHeight: "1rem",
        letterSpacing: "0.24px",
      },
    },
    300: {
      default: {
        fontFamily: "Inter",
        fontWeight: fontWeight.medium,
        fontSize: "0.75rem",
        lineHeight: "1rem",
        letterSpacing: "0.16px",
      },
    },
  },
  label: {
    100: {
      default: {
        fontFamily: "Inter",
        fontWeight: fontWeight.medium,
        fontSize: "1rem",
        lineHeight: "1.5rem",
        letterSpacing: "0.32px",
      },
    },
    200: {
      default: {
        fontFamily: "Inter",
        fontWeight: fontWeight.medium,
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        letterSpacing: "0.28px",
      },
    },
    300: {
      default: {
        fontFamily: "Inter",
        fontWeight: fontWeight.medium,
        fontSize: "0.75rem",
        lineHeight: "1rem",
        letterSpacing: "0.24px",
      },
    },
    400: {
      default: {
        fontFamily: "Inter",
        fontWeight: fontWeight.medium,
        fontSize: "0.625rem",
        lineHeight: "1rem",
        letterSpacing: "0.2px",
        textTransform: "capitalize",
      },
    },
  },
};

// https://www.figma.com/file/hBXUBEfHN9DLl86pWJlgDv/Depict-Design-system-(Light-Mode)?type=design&node-id=2-83&mode=design&t=ynl0yFvjrO3J6jsN-0

export const theme = {
  // Text+Icons
  textIcon: {
    base: {
      default: "#0F0F0F",
      hover: "#333335",
      pressed: "#000000",
      disabled: "#CBCBCC",
    },
    neutral: {
      default: "#333335",
      hover: "#0F0F0F",
      pressed: "#000000",
      disabled: "#CBCBCC",
    },
    subtle: {
      default: "#868689",
      hover: "#333335",
      pressed: "#0F0F0F",
      disabled: "#CBCBCC",
    },
    inverse: {
      default: "#FFFFFF",
      hover: "#F3F3F5",
      pressed: "#E4E4E5",
      disabled: "#868689",
    },
    accent: {
      default: "#3018C1",
      hover: "#27149E",
      pressed: "#120C80",
      disabled: "#C1BDDB",
    },
    success: {
      default: "#5A8E5A",
      hover: "#3C5F3C",
      pressed: "#1E2F1E",
      disabled: "#9EE59E",
    },
    warning: {
      default: "#8E8359",
      hover: "#5F583C",
      pressed: "#2F2C1E",
      disabled: "#EDDB95",
    },
    danger: {
      default: "#92363E",
      hover: "#61191F",
      pressed: "#311D1F",
      disabled: "#F38B93",
    },
    info: {
      default: "#5087C9",
      hover: "#3C5F8E",
      pressed: "#232A32",
      disabled: "#D9E8FA",
    },
  },

  // Background colors
  background: {
    base: {
      default: "#FFFFFF",
      hover: "#F3F3F5",
      pressed: "#E4E4E5",
      disabled: "#FAFAFC",
    },
    neutral: {
      default: "#E4E4E5",
      hover: "#CBCBCC",
      pressed: "#CBCBCC",
      disabled: "#FAFAFC",
    },
    subtle: {
      default: "#F3F3F5",
      hover: "#E4E4E5",
      pressed: "#CBCBCC",
      disabled: "#FAFAFC",
    },
    inverse: {
      default: "#0F0F0F",
      hover: "#333335",
      pressed: "#000000",
      disabled: "#CBCBCC",
    },
    accent: {
      default: "linear-gradient(94deg, #E4E6FA 100%, #F1E7FF 100%)",
      hover: "linear-gradient(94deg, #C4CFFF 100%, #E3D9FF 100%)",
      pressed: "linear-gradient(94deg, #A8BAFF 100%, #D2BDFF 100%)",
      disabled: "linear-gradient(94deg, #E6E8FF 100%, #F9F5FF 100%)",
    },
    success: {
      default: "#D5F8D5",
      hover: "#9EE59E",
      pressed: "#78BE78",
      disabled: "#EAFBEA",
    },
    warning: {
      default: "#F8F1D5",
      hover: "#EDDB95",
      pressed: "#BEB077",
      disabled: "#FBF8EA",
    },
    danger: {
      default: "#FAD2D5",
      hover: "#F38B93",
      pressed: "#F3616D",
      disabled: "#FDE9EB",
    },
    info: {
      default: "#DFEDFD",
      hover: "#AED1FB",
      pressed: "#5087C9",
      disabled: "#D9E8FA",
    },
  },

  // Border colors
  border: {
    base: {
      default: "#0F0F0F",
      hover: "#333335",
      pressed: "#000000",
      disabled: "#CBCBCC",
    },
    neutral: {
      default: "#868689",
      hover: "#333335",
      pressed: "#0F0F0F",
      disabled: "#CBCBCC",
    },
    subtle: {
      default: "#CBCBCC",
      hover: "#868689",
      pressed: "#333335",
      disabled: "#E4E4E5",
    },
    inverse: {
      default: "#FFFFFF",
      hover: "#CBCBCC",
      pressed: "#E4E4E5",
      disabled: "#868689",
    },
    accent: {
      default: "#5087C9",
      hover: "#687D97",
      pressed: "#485D78",
      disabled: "#82B1EC",
    },
    success: {
      default: "#78BE78",
      hover: "#5A8E5A",
      pressed: "#3C5F3C",
      disabled: "#9EE59E",
    },
    warning: {
      default: "#BEB077",
      hover: "#8E8359",
      pressed: "#5F583C",
      disabled: "#EDDB95",
    },
    danger: {
      default: "#F3616D",
      hover: "#92363E",
      pressed: "#61191F",
      disabled: "#F38B93",
    },
  },

  // Gradients
  gradient: {
    greyDefault: "linear-gradient(180deg, #EEEEF0 100%, #FFFFFF 100%)",
    blueDefault: "linear-gradient(180deg, #98B0DE 100%, #FFFFFF 100%)",
    purpleDefault: "linear-gradient(180deg, #E3DAF6 100%, #FFFFFF 100%)",
    lightBlueDefault: "linear-gradient(180deg, #DBF6FA 100%, #FFFFFF 100%)",
    yellowDefault: "linear-gradient(180deg, #F8F1D5 100%, #FFFFFF 100%)",
    redDefault: "linear-gradient(180deg, #FAD2D2 100%, #FFFFFF 100%)",
    greenDefault: "linear-gradient(180deg, #D3FAD2 100%, #FFFFFF 100%)",
  },

  fontWeight,

  typography,
};
