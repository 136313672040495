import "src/assets/scss/base.global.scss";
import "src/assets/scss/depict.global.scss";

import React from "react";
import { createRoot } from "react-dom/client";
import { IntercomProvider } from "react-use-intercom";
import App from "src/App";
import "./i18n";

import { Auth0Provider } from "@auth0/auth0-react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { queryClient } from "src/queries/client";
import { initMonitoring } from "./monitoring";
import config from "./config";
import { AnalyticsProvider } from "./helpers/integrations/segment";
import { shopifyIframeIntegration } from "./helpers/misc/shopifyIframeIntegration";
import { shopifyToken } from "./helpers/misc/shopifyToken";
import LiteThemeProvider from "./lite/helpers/LiteThemeProvider";

shopifyIframeIntegration();
initMonitoring();

const auth0Domain = config.auth0.domain;
const auth0ClientId = config.auth0.clientId;

const segmentKey = config.segment.key;
const intercomAppID = config.intercom.appId;

const container = document.getElementById("root");

const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <LiteThemeProvider>
      {/* LiteThemeProvider is required due to use of alignUI components */}
      <Auth0Provider
        domain={auth0Domain}
        clientId={auth0ClientId}
        authorizationParams={{
          redirect_uri: window.location.origin + config.publicUrl,
        }}
        skipRedirectCallback={!!shopifyToken}
      >
        <AnalyticsProvider writeKey={segmentKey}>
          <IntercomProvider appId={intercomAppID}>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} />
              <App />
            </QueryClientProvider>
          </IntercomProvider>
        </AnalyticsProvider>
      </Auth0Provider>
    </LiteThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
