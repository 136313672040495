import { css } from "@emotion/react";
import { useTopNavContext } from "src/components/navs/TopNavProvider";
import { theme } from "src/components/storybook/designSystemVariables";
import { useElementSize } from "usehooks-ts";

interface TopBarProps {
  showBorder?: boolean;
  children?: React.ReactNode;
  height?: number;
  backgroundColor?: string;
  justifyContent?: "space-between" | "flex-start" | "flex-end" | "center";
}
export const TopBar = ({
  showBorder = true,
  children,
  height,
  backgroundColor,
  justifyContent,
}: TopBarProps) => {
  const [ref] = useElementSize<HTMLDivElement>();
  const { topNavHeight, isTopNavOpen } = useTopNavContext();

  const top = isTopNavOpen ? topNavHeight : 0;

  return (
    <div
      ref={ref}
      css={css`
        position: sticky;
        top: ${top}px;
        z-index: calc(var(--dnd-base-z-index, 0) + 1000);
        height: ${height ? `${height}px` : "68px"};
        padding: 12px;
        border-bottom: ${showBorder
          ? `1px solid ${theme.border.subtle.default}`
          : "none"};
        display: flex;
        align-items: center;
        justify-content: ${justifyContent || "center"};
        align-self: stretch;
        background-color: ${backgroundColor || theme.background.base.default};
      `}
    >
      {children}
    </div>
  );
};

export default TopBar;
