import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QueryId, getQueryKey } from "../../../queries/queries";
import useAuthorizedApi from "../app/useAuthorizedApi";
import { throwErrorIfAny } from "src/api/authorizedApi";
import { GetWorkflowResponse } from "src/api/types";

export default function useCatalogSyncTrigger(merchantId: string | null) {
  const { api } = useAuthorizedApi();
  const queryClient = useQueryClient();

  const queryKey = getQueryKey(QueryId.GetCatalogSyncStatus, merchantId || "");

  const mutation = useMutation({
    mutationFn: async () => {
      if (!api || !merchantId) return;
      const response = await api.POST(
        "/api/v0/integrations/workflow/{merchant_id}",
        {
          params: {
            path: {
              merchant_id: merchantId,
            },
          },
        }
      );

      throwErrorIfAny(response);
    },
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: queryKey,
      });

      const previousValue = queryClient.getQueryData<
        GetWorkflowResponse | undefined
      >(queryKey);

      const newValue: GetWorkflowResponse = {
        running: true,
        n_queued: (previousValue?.n_queued || 0) + 1,
        last_succeeded: null,
        status: null,
      };

      queryClient.setQueryData(queryKey, newValue);

      return { previousValue, newValue };
    },

    onError: (err, variables, context) => {
      if (context?.previousValue) {
        queryClient.setQueryData(queryKey, context.previousValue);
      }
    },
    onSettled: async () => {
      // Wait 5 seconds before refetching to give the server time to update

      await new Promise((resolve) => setTimeout(resolve, 5000));

      return await queryClient.invalidateQueries({
        queryKey: queryKey,
      });
    },
  });

  return {
    mutation,
  };
}
