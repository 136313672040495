import { css } from "@emotion/react";
import { useEffect, useMemo, useRef, useState } from "react";
import { DropTargetMonitor, useDrop } from "react-dnd";
import { OnAddListingItem } from "src/components/navs/types";
import { CollectionType } from "src/api/types";
import { ALLOWED_SCOLLECTION_TYPES_FOR_EDITING } from "../../../contexts/ListingTreeSelectorContext";
import { ItemTypes } from "./ListingTreeItem";
import ListingTreeMenuHeader from "./ListingTreeMenuHeader";
import { ListingItem, ListingSorting } from "./types";
import {
  getNodeAndAncestorsWhere,
  getNodeAndChildrenWhere,
} from "../../../helpers/hooks/useListingItems";
import { ListingTreeContextMenuState } from "../../ListingTree/types";
import ListingTreeItems, {
  notSuportedCollectionTypes,
} from "./ListingTreeItems";
import { useIsDepictLite } from "src/helpers/hooks/useIsDepictLite";

export default function ListingTreeMenu({
  isLoading,
  listingItems,
  currentListingItemId,
  onAddListingItem,
  inSelectMode,
  selectedListingItemIds,
  onChangeSelectedListingItemIds,
  indeterminateListingItemIds,
  onSetParentId,
  editableListingTypes,
  onSetListingType,
  disabledListingItemIds,
  dontEnsureHierarchy,
  dontShowHeaderTitle,
  onSetContextMenuState,
  contextMenuState,
}: {
  dontEnsureHierarchy?: boolean;
  listingItems: ListingItem[];
  isLoading?: boolean;
  currentListingItemId?: string;
  dontShowHeaderTitle?: boolean;
  onAddListingItem?: OnAddListingItem;
  selectedListingItemIds?: Set<string>;
  disabledListingItemIds: Set<string>;
  onChangeSelectedListingItemIds?: (
    checked: boolean,
    listingItemIds: Set<string>
  ) => void;
  inSelectMode?: boolean;
  indeterminateListingItemIds?: Set<string>;
  onSetParentId: (listingItemId: string, parentId: string | null) => void;
  editableListingTypes: CollectionType[];
  onSetListingType: (
    collectionType: CollectionType,
    listingItemId: string
  ) => void;
  onSetContextMenuState?: (state: ListingTreeContextMenuState | null) => void;
  contextMenuState?: ListingTreeContextMenuState | null;
}) {
  const [expandedListingItemIds, setExpandedListingItemIds] = useState<
    Set<string>
  >(new Set());
  const { isDepictLite } = useIsDepictLite();

  const [filteredType, setFilteredType] = useState<CollectionType | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const sanitizedSearchQuery = searchQuery.trim().toLowerCase();

  const [sorting, setSorting] = useState<ListingSorting>({
    field: "title",
    direction: "asc",
  });

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (currentListingItemId && scrollContainerRef.current) {
      const elementToScrollTo = document.getElementById(currentListingItemId);
      if (elementToScrollTo) {
        const containerTop = scrollContainerRef.current.scrollTop;
        const containerBottom =
          containerTop + scrollContainerRef.current.clientHeight;

        const elementTop =
          elementToScrollTo.offsetTop - scrollContainerRef.current.offsetTop;
        const elementBottom = elementTop + elementToScrollTo.clientHeight;

        const isInView =
          elementBottom <= containerBottom && elementTop >= containerTop;

        if (!isInView) {
          scrollContainerRef.current.scrollTop = elementTop;
        }
      }
    }
  }, [currentListingItemId]);
  const viewableListingItemIds = useMemo(
    () =>
      getNodeAndAncestorsWhere((listingItem) => {
        if (
          inSelectMode &&
          !ALLOWED_SCOLLECTION_TYPES_FOR_EDITING.includes(listingItem.type)
        ) {
          return false;
        }
        if (filteredType && listingItem.type !== filteredType) {
          return false;
        }
        if (!searchQuery) {
          return true;
        }
        if (currentListingItemId === listingItem.id) {
          return true;
        }
        return listingItem.title.toLowerCase().includes(sanitizedSearchQuery);
      }, listingItems),
    [
      inSelectMode,
      searchQuery,
      listingItems,
      sanitizedSearchQuery,
      currentListingItemId,
      filteredType,
    ]
  );

  useEffect(() => {
    if (sanitizedSearchQuery) {
      setExpandedListingItemIds(viewableListingItemIds);
    } else if (currentListingItemId) {
      setExpandedListingItemIds(
        (expanded) =>
          new Set([
            ...expanded,
            ...getNodeAndAncestorsWhere(
              (listingItem) => listingItem.id === currentListingItemId,
              listingItems
            ),
          ])
      );
    }
  }, [
    currentListingItemId,
    listingItems,
    sanitizedSearchQuery,
    viewableListingItemIds,
  ]);

  const sortingFunction = (a: ListingItem, b: ListingItem) => {
    switch (sorting.field) {
      case "title":
        if (sorting.direction === "asc") {
          return a.title.localeCompare(b.title);
        }
        return b.title.localeCompare(a.title);
      case "nProducts":
        if (sorting.direction === "asc") {
          return (a.nProducts() || 0) - (b.nProducts() || 0);
        }
        return (b.nProducts() || 0) - (a.nProducts() || 0);
      case "updatedAt":
        if (sorting.direction === "asc") {
          return (a.updatedAt?.getTime() ?? 0) - (b.updatedAt?.getTime() ?? 0);
        }
        return (b.updatedAt?.getTime() ?? 0) - (a.updatedAt?.getTime() ?? 0);
      default:
        return 0;
    }
  };

  const onSelectChange = (checked: boolean, listingId: string) => {
    if (checked) {
      onChangeSelectedListingItemIds &&
        onChangeSelectedListingItemIds(
          checked,
          !dontEnsureHierarchy
            ? getNodeAndAncestorsWhere(
                (listingItem) => listingItem.id === listingId,
                listingItems
              )
            : new Set([listingId])
        );
    } else {
      onChangeSelectedListingItemIds &&
        onChangeSelectedListingItemIds(
          checked,
          !dontEnsureHierarchy
            ? getNodeAndChildrenWhere(
                (listingItem) => listingItem.id === listingId,
                listingItems
              )
            : new Set([listingId])
        );
    }
  };

  const [{ isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.LISTING_ITEM,
    drop: (item: { listingItem: ListingItem }, monitor) => {
      if (!monitor.didDrop()) {
        onSetParentId(item.listingItem.id, null);
      }
    },
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver({
        shallow: true,
      }),
    }),
  }));

  return (
    <div
      ref={drop}
      className="m-2 mt-0 p-2 rounded text-sm"
      css={css`
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow-y: hidden;
        background: ${inSelectMode ? "white" : "transparent"};
        border: ${inSelectMode
          ? "1px solid transparent"
          : isOver
          ? "1px solid #3018C1"
          : "1px solid #e4e4e5"};
      `}
    >
      <div
        css={css`
          opacity: ${isLoading ? 0.25 : 1};
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          overflow-y: hidden;
        `}
      >
        <ListingTreeMenuHeader
          dontShowTitle={dontShowHeaderTitle}
          onSetType={(type, listingItemId) => {
            onSetListingType(type, listingItemId);
          }}
          isLoading={isLoading}
          availableFilterTypes={Array.from(
            Array.from(
              getNodeAndChildrenWhere(() => true, listingItems)
            ).reduce((acc, listingId) => {
              const listingItem = listingItems.find(
                (listingItem) =>
                  listingItem.id === listingId &&
                  !notSuportedCollectionTypes.includes(listingItem.type)
              );
              if (listingItem) {
                if (listingItem.type === "smart_pick" && isDepictLite)
                  return acc;
                acc.add(listingItem.type);
              }
              return acc;
            }, new Set() as Set<CollectionType>)
          )}
          searchQuery={searchQuery}
          onSetSearchQuery={setSearchQuery}
          onAddListingItem={onAddListingItem}
          sorting={sorting}
          onSetSorting={setSorting}
          filteredType={filteredType}
          onSetFilteredType={setFilteredType}
        />
        <div
          ref={scrollContainerRef}
          css={css`
            overflow-y: auto;
            flex-grow: 1;
          `}
        >
          <ListingTreeItems
            inSelectMode={inSelectMode}
            selectedListingItemIds={selectedListingItemIds}
            disabledListingItemIds={disabledListingItemIds}
            indeterminateListingItemIds={indeterminateListingItemIds}
            listingItems={listingItems}
            expandedListingItemIds={expandedListingItemIds}
            setExpandedListingItemIds={setExpandedListingItemIds}
            onSelectChange={onSelectChange}
            viewableListingItemIds={viewableListingItemIds}
            onSetParentId={onSetParentId}
            editableListingTypes={editableListingTypes}
            sortingFunction={sortingFunction}
            contextMenuState={contextMenuState}
            onSetContextMenuState={onSetContextMenuState}
          />
        </div>
      </div>
    </div>
  );
}
