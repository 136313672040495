import { ChevronRight } from "../linearicons";
import { css } from "@emotion/react";
import { Button } from "react-bootstrap";
import React, { useEffect } from "react";
import { ListingTreeContextMenuState } from "./types";
import styled from "@emotion/styled";
import { SearchField } from "../storybook/SearchField/SearchField";
import { ListingItem } from "../storybook/ListingTreeMenu/types";
import { CollectionType } from "../../api/types";
import { useCreateSCollectionMutation } from "../../helpers/categories/categoryV3.helper";
import useAuthorizedApi from "../../helpers/hooks/app/useAuthorizedApi";
import useMerchant from "../../helpers/hooks/app/useMerchant";
import { getNextListingName } from "../../helpers/categories/getNextListingName";
import useScollections from "../../helpers/hooks/useScollections";
import { useNavigate } from "react-router-dom";
import useOwnCategories from "../../helpers/hooks/categories_v2_v3_common/useOwnCategories";
import { getNodeAndChildrenWhere } from "../../helpers/hooks/useListingItems";

const MenuButton = styled(Button)`
  padding: 12px 16px;
  border-radius: 0;
  width: 100%;
  justify-content: space-between;
  color: #0f0f0f;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;

  &:hover {
    background-color: #f3f3f5;
    text-decoration: none;
  }
`;

function ListingItems({
  listingItems,
  ancestorTitles,
  onSelect,
  isVisible,
}: {
  isVisible: (listingItem: ListingItem) => boolean;
  onSelect: (listingItemId: string) => void;
  ancestorTitles?: string[];
  listingItems: ListingItem[];
}) {
  return (
    <>
      {listingItems
        .sort((a, b) => a.title.localeCompare(b.title))
        .map((listingItem) => (
          <>
            {isVisible(listingItem) && (
              <MenuButton
                onClick={() => {
                  onSelect(listingItem.id);
                }}
                variant="link"
                key={listingItem.id}
                css={css`
                  justify-content: flex-start;
                  flex-direction: column;
                  align-items: flex-start;
                  text-align: left;
                `}
              >
                {ancestorTitles && (
                  <div
                    css={css`
                      color: #868689;
                      font-size: 10px;
                      font-weight: 500;
                      margin-bottom: 4px;
                    `}
                  >
                    {ancestorTitles.join(" > ")}
                    {" >"}
                  </div>
                )}
                {listingItem.title}
              </MenuButton>
            )}
            {listingItem.children && (
              <ListingItems
                onSelect={onSelect}
                ancestorTitles={[...(ancestorTitles || []), listingItem.title]}
                isVisible={isVisible}
                listingItems={listingItem.children}
              />
            )}
          </>
        ))}
    </>
  );
}

const MAX_HEIGHT = 332;

export default function ListingTreeContextMenu({
  listingItems,
  listingContextMenuState,
  setListingContextMenuState,
  onMoveTo,
  editableListingTypes,
  onSetDeleteListingItemStaged,
}: {
  onSetDeleteListingItemStaged: (listingItem: ListingItem) => void;
  editableListingTypes: CollectionType[];
  onMoveTo: (listingItemId: string, targetListingItemId: string) => void;
  listingItems: ListingItem[];
  listingContextMenuState: ListingTreeContextMenuState;
  setListingContextMenuState: React.Dispatch<
    React.SetStateAction<ListingTreeContextMenuState | null>
  >;
}) {
  const [showMoveTo, setShowMoveTo] = React.useState(false);

  useEffect(() => {
    setShowMoveTo(false);
  }, [listingContextMenuState]);

  const [searchQuery, setSearchQuery] = React.useState("");

  const menuWillBeTooFarDown =
    listingContextMenuState.y + MAX_HEIGHT > window.innerHeight - 100;

  const ref = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && ref.current.contains(event.target as Node)) {
        // If the click is inside the search field, stop the event from propagating
        event.stopPropagation();
      } else {
        // Otherwise, close the context menu
        setListingContextMenuState(null);
      }
    };

    // Add click event listener to the document
    document.addEventListener("click", handleClickOutside);
    return () => {
      // Remove event listener on cleanup
      document.removeEventListener("click", handleClickOutside);
    };
  }, [setListingContextMenuState]);

  const editable = editableListingTypes.includes(
    listingContextMenuState.listingItem.type
  );

  const { api } = useAuthorizedApi();
  const { merchant } = useMerchant();

  const { refetch } = useScollections();

  const navigate = useNavigate();

  const createScollectionMutation = useCreateSCollectionMutation(
    api,
    merchant?.id ?? ""
  );
  const ownCategories = useOwnCategories();

  const childrenIds = getNodeAndChildrenWhere(
    (item) => item.id === listingContextMenuState.listingItem.id,
    listingItems
  );

  return (
    <div
      css={css`
        position: absolute;
        top: ${listingContextMenuState.y}px;
        left: ${listingContextMenuState.x}px;
        z-index: 10000;
      `}
      ref={ref}
    >
      <div
        css={css`
          position: relative;
          background-color: white;
          border-radius: 8px;
          box-shadow: 0 8px 32px 0 rgba(15, 15, 15, 0.16);

          width: 233px;
          padding: 8px 0;
        `}
      >
        <MenuButton
          variant="link"
          disabled={!editable}
          onMouseEnter={() => {
            editable && setShowMoveTo(true);
          }}
        >
          Move to
          <ChevronRight size={12} />
        </MenuButton>
        <MenuButton
          variant="link"
          onMouseEnter={() => {
            setShowMoveTo(false);
          }}
          onClick={() => {
            createScollectionMutation
              .mutateAsync({
                title: getNextListingName(
                  listingItems,
                  listingContextMenuState.listingItem.type
                ),
                type: listingContextMenuState.listingItem.type,
                parentId: listingContextMenuState.listingItem.id,
              })
              .then(
                (returned) => {
                  const categoryId = returned?.collection_id;
                  if (!categoryId) {
                    throw new Error("Category ID is null, should never happen");
                  }
                  refetch();
                  navigate(`/${merchant?.id}/categories-v3/${categoryId}`);
                  setListingContextMenuState(null);
                },
                (error) => {
                  console.error(error);
                }
              );
          }}
          disabled={
            !editable ||
            (!ownCategories &&
              listingContextMenuState.listingItem.type === "category")
          }
        >
          Create sub-collection
        </MenuButton>
        <MenuButton
          variant="link"
          onMouseEnter={() => {
            setShowMoveTo(false);
          }}
          onClick={() => {
            onSetDeleteListingItemStaged(listingContextMenuState.listingItem);
            setListingContextMenuState(null);
          }}
          disabled={!editable}
        >
          Delete
        </MenuButton>
      </div>
      {showMoveTo && (
        <div
          css={css`
            background-color: white;
            border-radius: 8px;
            box-shadow: 0 8px 32px 0 rgba(15, 15, 15, 0.16);
            min-width: 252px;
            padding: 8px 0;

            position: absolute;
            ${menuWillBeTooFarDown ? "bottom: 0;" : "top: 0;"}
            left: 100%;
            margin-left: -8px;
            margin-top: 8px;
            margin-bottom: 8px;
          `}
        >
          <div
            css={css`
              padding: 12px 16px;
            `}
          >
            <SearchField
              searchQuery={searchQuery}
              onSetSearchQuery={setSearchQuery}
            />
          </div>
          <div
            css={css`
              height: ${MAX_HEIGHT}px;
              overflow-y: auto;
            `}
          >
            <ListingItems
              onSelect={(listingItemId) => {
                setListingContextMenuState(null);
                onMoveTo(listingContextMenuState.listingItem.id, listingItemId);
              }}
              isVisible={(listingItem) =>
                listingContextMenuState.listingItem.type === listingItem.type &&
                listingItem.title
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase()) &&
                listingItem.id !== listingContextMenuState.listingItem.id &&
                !childrenIds.has(listingItem.id)
              }
              listingItems={listingItems}
            />
          </div>
        </div>
      )}
    </div>
  );
}
