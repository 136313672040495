import { useQuery } from "@tanstack/react-query";
import { getAuthorizedApi, getData } from "src/api/authorizedApi";
import { Merchant } from "src/api/types";
import { QueryId, getQueryKey } from "../../../queries/queries";
import useAuthentication from "./useAuthentication";

const emptyMerchants: Merchant[] = [];

export default function useAuthorizedMerchants(): [
  merchants: Merchant[],
  loading: boolean
] {
  const [getAccessToken] = useAuthentication();

  const { data: merchants, isLoading } = useQuery({
    queryKey: getQueryKey(QueryId.GetAuthorizedMerchants),
    queryFn: async () => {
      const api = getAuthorizedApi(getAccessToken);
      if (!api) return;

      const response = await api.GET("/api/v0/merchants/");

      if (response.response.status === 404) {
        // TODO: Fix this in backend.
        return [];
      }

      const data: Merchant[] = getData(response);

      return data;
    },
    staleTime: 1000 * 60 * 10,
  });

  return [merchants ?? emptyMerchants, isLoading];
}
