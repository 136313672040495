import { LottieOptions, useLottie } from "lottie-react";
import spinnerAnimation from "./depict_spinner.json";

const style = {
  height: 42,
  width: 42,
};

export const DepictSpinner = () => {
  const options: LottieOptions = {
    animationData: spinnerAnimation,
    loop: true,
    autoplay: true,
  };

  const { View } = useLottie(options, style);

  return View;
};
