import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import TopBar from "../../TopBar/TopBar";
import { theme } from "../../designSystemVariables";
import { Paragraph } from "../../Typography/Paragraph";
import { Button } from "react-bootstrap";
import { ArrowsClockwise, X } from "@phosphor-icons/react";
import useCatalogSyncTrigger from "../../../../helpers/hooks/catalog/useCatalogSyncTrigger";
import useMerchantId from "../../../../helpers/hooks/app/useMerchantId";
import { useSyncBanner } from "./SyncBannerContext";
import useCatalogSyncStatus from "../../../../helpers/hooks/catalog/useCatalogSyncStatus";

function getColor(syncStarted: boolean) {
  return syncStarted ? theme.background.neutral.default : "#3018C1";
}

function getTextColor(syncStarted: boolean) {
  return syncStarted
    ? theme.textIcon.subtle.default
    : theme.textIcon.inverse.default;
}

const SyncBannerWrapper = () => {
  const { merchantId } = useMerchantId();
  const { setShowSyncBanner } = useSyncBanner();

  const {
    lastSync: lastSyncString,
    syncInProgress,
    nQueued,
    isLoading: isLoadingSyncStatus,
    isError: isErrorSyncStatus,
    isPending: isPendingSyncStatus,
  } = useCatalogSyncStatus(merchantId);

  const [syncStatus, setSyncStatus] = useState({
    syncStarted: false,
    nQueued: nQueued,
    syncInProgress: syncInProgress,
  });
  useEffect(() => {
    setSyncStatus((prevState) => ({
      ...prevState,
      nQueued: nQueued,
      syncInProgress: syncInProgress,
    }));
  }, [nQueued, syncInProgress]);

  if (
    !syncStatus.syncInProgress &&
    syncStatus.nQueued === 0 &&
    syncStatus.syncStarted
  ) {
    setShowSyncBanner(false);
  }

  const { mutation: catalogSyncTriggerMutation } =
    useCatalogSyncTrigger(merchantId);
  return (
    <TopBar
      showBorder={false}
      height={36}
      backgroundColor={getColor(syncStatus.syncStarted)}
      justifyContent="space-between"
    >
      <Paragraph
        variant={200}
        weight="light"
        color={syncStatus.syncStarted ? "subtle" : "inverse"}
      >
        {syncStatus.syncStarted
          ? "It may take a few minutes..."
          : "Looks like you’ve made changes that could affect the shopper experience. Sync to make sure your store is up to date."}
      </Paragraph>
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <Button
          css={css`
            padding: 8px 8px;
            margin-left: 8px;
            opacity: 1;
            background-color: ${getColor(syncStatus.syncStarted)};
            border-color: ${getColor(syncStatus.syncStarted)};
            color: ${getTextColor(syncStatus.syncStarted)};

            &:hover {
              background-color: ${getColor(syncStatus.syncStarted)};
              border-color: ${getColor(syncStatus.syncStarted)};
            }

            &:disabled {
              background-color: ${getColor(syncStatus.syncStarted)};
              border-color: ${getColor(syncStatus.syncStarted)};
              color: ${getTextColor(syncStatus.syncStarted)};
            }
          `}
          disabled={syncStatus.syncStarted}
          onClick={() => {
            catalogSyncTriggerMutation.mutate();
            setSyncStatus((prevState) => ({
              ...prevState,
              syncStarted: true,
              nQueued: prevState.nQueued ? prevState.nQueued + 1 : 1,
              syncInProgress: true,
            }));
          }}
        >
          {syncStatus.syncStarted
            ? "Syncing..." + (nQueued ? ` (${nQueued} in queue)` : "")
            : "Sync from Shopify"}
          <ArrowsClockwise size={16} className="ms-1" />
        </Button>
      </div>
    </TopBar>
  );
};

export default React.memo(SyncBannerWrapper);
