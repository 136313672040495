import { CollectionType } from "src/api/types";

// template name for new listing items, depending on the type
const getNameTemplate = (type: CollectionType) => {
  switch (type) {
    case "smart_pick":
      return "New Smart Pick";
    case "style":
      return "New Style";
    case "category":
      return "New Category";
    case "campaign":
      return "New Campaign";
    case "look":
      return "New Look";
    default:
      return "New Listing";
  }
};

export const getNextListingName = (
  listingItems: { title: string }[],
  type: CollectionType
) => {
  const existingNames = listingItems.map((listingItem) => listingItem.title);
  let newName = getNameTemplate(type);
  let i = 1;
  while (existingNames.includes(newName)) {
    newName = `${getNameTemplate(type)} ${i}`;
    i++;
  }
  return newName;
};
