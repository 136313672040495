import ListingTreeItem from "./ListingTreeItem";
import { ListingItem } from "./types";
import { CollectionType } from "../../../api/types";
import { ListingTreeContextMenuState } from "../../ListingTree/types";

export const notSuportedCollectionTypes = ["look"];

export default function ListingTreeItems({
  listingItems,
  currentListingItemId,
  expandedListingItemIds,
  setExpandedListingItemIds,
  onSelectChange,
  selectedListingItemIds,
  inSelectMode,
  onSetContextMenuState,
  contextMenuState,
  viewableListingItemIds,
  disabledListingItemIds,
  indeterminateListingItemIds,
  onSetParentId,
  editableListingTypes,
  sortingFunction,
}: {
  listingItems: ListingItem[];
  currentListingItemId?: string;
  expandedListingItemIds: Set<string>;
  setExpandedListingItemIds: (expandedListingItemIds: Set<string>) => void;
  onSelectChange: (checked: boolean, listingId: string) => void;
  selectedListingItemIds?: Set<string>;
  inSelectMode?: boolean;
  onSetContextMenuState?: (state: ListingTreeContextMenuState | null) => void;
  contextMenuState?: ListingTreeContextMenuState | null;
  viewableListingItemIds: Set<string>;
  disabledListingItemIds?: Set<string>;
  indeterminateListingItemIds?: Set<string>;
  onSetParentId: (listingItemId: string, parentId: string | null) => void;
  editableListingTypes: CollectionType[];
  sortingFunction: (a: ListingItem, b: ListingItem) => number;
}) {
  return (
    <>
      {listingItems
        .filter(
          (listingItem) =>
            viewableListingItemIds.has(listingItem.id) &&
            !notSuportedCollectionTypes.includes(listingItem.type)
        )
        .sort(sortingFunction)
        .map((listingItem) => (
          <ListingTreeItem
            editableListingTypes={editableListingTypes}
            isDisabled={(listingId) =>
              disabledListingItemIds?.has(listingId) ?? false
            }
            onDrop={onSetParentId}
            sortFunction={sortingFunction}
            selectIsIndeterminate={(listingId) =>
              indeterminateListingItemIds?.has(listingId) ?? false
            }
            key={listingItem.id}
            currentListingItemId={currentListingItemId}
            isVisible={(listingId) => viewableListingItemIds.has(listingId)}
            listingItem={listingItem}
            isExpanded={(listingId) => expandedListingItemIds.has(listingId)}
            onSetExpand={(value, listingId) => {
              const newExpandedListingItemIds = new Set(expandedListingItemIds);
              if (value) {
                newExpandedListingItemIds.add(listingId);
              } else {
                newExpandedListingItemIds.delete(listingId);
              }
              setExpandedListingItemIds(newExpandedListingItemIds);
            }}
            onSelectChange={onSelectChange}
            isSelected={(listingId) =>
              (selectedListingItemIds || new Set()).has(listingId)
            }
            inSelectMode={inSelectMode}
            onSetContextMenuState={onSetContextMenuState}
            isContextMenuSelected={(listingId) =>
              contextMenuState?.listingItem.id === listingId
            }
          />
        ))}
    </>
  );
}
