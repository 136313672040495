import { css } from "@emotion/react";
import { FC, PropsWithChildren } from "react";
import { Paragraph } from "../Typography/Paragraph";
import { theme } from "../designSystemVariables";

interface Props extends PropsWithChildren {
  background: keyof typeof theme.textIcon | "onSale";
}

export const LabelBadge: FC<Props> = ({ background, children }) => (
  <Paragraph
    variant={400}
    weight="light"
    styles={css`
      background: ${background === "onSale"
        ? "#D43E3E"
        : theme.textIcon[background].default};
      color: ${theme.textIcon.inverse.default};
      border-radius: 16px;
      padding: 0.125rem 0.375rem;
      min-width: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
    `}
  >
    {children}
  </Paragraph>
);
