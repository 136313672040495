import { Interpolation, Theme, css } from "@emotion/react";
import { FC, PropsWithChildren } from "react";
import { theme } from "../designSystemVariables";
import { Typography, TypographyColor } from "./Typography";

type ParagraphVariant = 100 | 200 | 300 | 400;

interface Props extends PropsWithChildren {
  variant: ParagraphVariant;
  weight?: "light" | "heavy";
  tagName?: keyof JSX.IntrinsicElements;
  color?: TypographyColor;
  disabled?: boolean;
  clickable?: boolean;
  block?: boolean;
  styles?: Interpolation<Theme>;
}

export const Paragraph: FC<Props> = ({
  variant,
  tagName,
  color = "base",
  weight = "light",
  block,
  disabled,
  clickable,
  styles,
  children,
}) => {
  const typographyStyles = theme.typography.paragraph[variant][weight];

  return (
    <Typography
      tagName={tagName || "span"}
      baseStyles={css`
        font-family: ${typographyStyles.fontFamily};
        font-size: ${typographyStyles.fontSize};
        line-height: ${typographyStyles.lineHeight};
        font-weight: ${typographyStyles.fontWeight};
      `}
      color={color}
      disabled={disabled}
      clickable={clickable}
      block={block}
      styles={styles}
    >
      {children}
    </Typography>
  );
};
