import { ThemeProvider } from "@emotion/react";
import { createContext, useState } from "react";
import { buildTheme } from "../../theme";

interface LiteThemeContextType {
  darkMode: boolean;
  setDarkMode: (darkMode: boolean) => void;
}

export const LiteThemeContext = createContext<LiteThemeContextType>({
  darkMode: false,
  setDarkMode: () => {},
});

export default function LiteThemeProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );
  return (
    <LiteThemeContext.Provider value={{ darkMode, setDarkMode }}>
      <ThemeProvider theme={buildTheme(darkMode)}>{children}</ThemeProvider>
    </LiteThemeContext.Provider>
  );
}
