import {SearchFilter} from "@depict-ai/types/api/SearchResponse";
import {QueryKey} from "@tanstack/react-query";
import {FilterValuesSortingOrder, PortalSortModel} from "src/api/types";

// Query IDs are used to identify queries in the cache (when using react-query)
export enum QueryId {
  GetRecommendationConfigWorkflowStatus = "GetRecommendationConfigWorkflowStatus",
  GetRecommendationSurfaces = "GetRecommendationSurfaces",
  GetMainProducts = "GetMainProducts",
  GetRecommendationConfigs = "GetRecommendationConfigs",
  GetPlpProductClicks = "GetPlpProductClicks",
  GetMarkets = "GetMarkets",
  GetActiveMarkets = "GetActiveMarkets",
  GetClicksMetrics = "GetClicksMetrics",
  GetPlpMetrics = "GetPlpMetrics",
  GetAllPlpMetrics = "GetAllPlpMetrics",
  GetNumberOfProducts = "GetNumberOfProducts",
  GetNumberOfSalesProducts = "GetNumberOfSalesProducts",
  GetSCollections = "GetSCollections",
  GetSCollection = "GetSCollection",
  GetInboxMainProductIds = "GetInboxMainProductIds",
  GetAuthorizedMerchants = "GetAuthorizedMerchants",
  GetCollections = "GetCollections",
  GetLongTailCollections = "GetLongTailCollections",
  GetLongTailCollectionCount = "GetLongTailCollectionCount",
  GetCollection = "GetCollection",
  GetSearchStats = "GetSearchStats",
  GetContentSearch = "GetContentSearch",
  GetCentraConfiguredMarkets = "GetCentraConfiguredMarkets",
  GetCentraStores = "GetCentraStores",
  GetCategoryProducts = "GetCategoryProducts",
  GetCategoryProductsOrder = "GetCategoryProductsOrder",
  GetCategoryProductOrderOverrides = "GetCategoryProductOrderOverrides",
  GetGetCategoryPreviewProducts = "GetGetCategoryPreviewProducts",
  GetCategoryProductIds = "GetCategoryProductIds",
  GetCategory = "GetCategory",
  GetCollectionProducts = "GetCollectionProducts",
  GetMerchantProducts = "GetMerchantProducts",
  GetCatalogProducts = "GetCatalogProducts",
  GetCatalogSyncStatus = "GetCatalogSyncStatus",
  GetPairs = "GetPairs",
  GetBoostAndBuryRules = "GetBoostAndBuryRules",
  GetAvailableDimensionKeys = "GetAvailableDimensionKeys",
  GetAvailableShopifyMarkets = "GetAvailableShopifyMarkets",
  GetAvailableShopifyMetafieldDefinitions = "GetAvailableShopifyMetafieldDefinitions",
  GetAvailableCentraAttributes = "GetAvailableCentraAttributes",
  ProductCatalogSearchAndFilterProvider = "ProductCatalogSearchAndFilterProvider",
  GetCatalogProductFilters = "GetCatalogProductFilters",
  GetCatalogProductMetadata = "GetCatalogProductMetadata",
  GetRuleBasedCollections = "GetRuleBasedCollections",
  GetRuleBasedCollection = "GetRuleBasedCollection",
  GetCollectionByQueryId = "GetCollectionByQueryId",
  GetShopifyApiCredentials = "GetShopifyApiCredentials",
  GetSynonyms = "GetSynonyms",
  GetCategorySearchResults = "GetCategorySearchResults",
  GetAvailableSorts = "GetAvailableSorts",
  GetIntegrations = "GetIntegrations",
  GetProductSpotlightMetrics = "GetProductSpotlightMetrics",
  GetProductsByItemGroupId = "GetProductsByItemGroupId",
  GetProductRecommendations = "GetProductRecommendations",
  GetAutoHide = "GetAutoHide",
  GetAutoHideExcludeProducts = "GetAutoHideExcludeProducts",
  GetMaintenance = "GetMaintenance",
  GetProductsSalesData = "GetProductsSalesData",
  GetProductClickSources = "GetProductClickSources",
  GetProductSpotlighSalesData = "GetProductSpotlighSalesData",
  GetProductsClicksOrigin = "GetProductsClicksOrigin",
  GetShopifyData = "GetShopifyData",
  GetLooks = "GetLooks",
  GetShopifyUrl = "GetShopifyUrl",
  GetDiagnosticEvents = "GetDiagnosticEvents",
  GetAttributes = "GetAttributes",
  GetAttribute = "GetAttribute",
  GetSettingsAttributeMetadata = "GetSettingsAttributeMetadata",
  GetSettingsFilters = "GetSettingsFilters",
  GetSettingsFiltersValue = "GetSettingsFiltersValue",
  GetSettingsFiltersTranslations = "GetSettingsFiltersTranslations",
  GetSettingsUIConfig = "GetSettingsUIConfig",
  GetDisplayProducts = "GetDisplayProducts",
  GetHasCompletedOnBoarding = "GetHasCompletedOnBoarding",
  GetIngestionRunLogs = "GetIngestionRunLogs",
  GetCentraAttributes = "GetCentraAttributes",
  GetCentraFieldNames = "GetCentraFieldNames",
  GetTrackingDiagnosticsMapping = "GetTrackingDiagnosticsMapping",
  GetTrackingDiagnosticEvents = "GetTrackingDiagnosticEvents",
  GetTrackingDiagnosticAttributionStats = "GetTrackingDiagnosticAttributionStats",
  GetUploadedMedia = "GetUploadedMedia",
  GetMarketGroups = "GetMarketGroups",
}

export const categoryQueries: QueryId[] = [
  QueryId.GetCategory,
  QueryId.GetCategoryProducts,
  QueryId.GetCategoryProductsOrder,
  QueryId.GetGetCategoryPreviewProducts,
  QueryId.GetCategoryProductIds,
  QueryId.GetCategorySearchResults,
];

export const queryKeyBuilders = {
  [QueryId.GetRecommendationConfigWorkflowStatus]: (merchantId: string) => [
    QueryId.GetRecommendationConfigWorkflowStatus,
    merchantId,
  ],
  [QueryId.GetDisplayProducts]: (merchantId: string) => [
    QueryId.GetDisplayProducts,
    merchantId,
  ],
  [QueryId.GetRecommendationSurfaces]: (merchantId: string) => [
    QueryId.GetRecommendationSurfaces,
    merchantId,
  ],
  [QueryId.GetMainProducts]: (merchantId: string, mainProductIds: string) => [
    QueryId.GetMainProducts,
    merchantId,
    mainProductIds,
  ],
  [QueryId.GetRecommendationConfigs]: (merchantId: string) => [
    QueryId.GetRecommendationConfigs,
    merchantId,
  ],
  [QueryId.GetPlpProductClicks]: (
    merchantId: string,
    market: string,
    collectionId: string,
    start: number,
    end: number
  ) => [
    QueryId.GetPlpProductClicks,
    merchantId,
    collectionId,
    market,
    start,
    end,
  ],
  [QueryId.GetActiveMarkets]: (
    merchantId: string,
    start: number,
    end: number
  ) => [QueryId.GetActiveMarkets, merchantId, start, end],
  [QueryId.GetMarkets]: (merchantId: string) => [
    QueryId.GetMarkets,
    merchantId,
  ],
  [QueryId.GetAllPlpMetrics]: (
    merchantId: string,
    start: number,
    end: number,
    market?: string
  ) => [QueryId.GetAllPlpMetrics, merchantId, start, end, market],
  [QueryId.GetClicksMetrics]: (
    merchantId: string,
    start: number,
    end: number
  ) => [QueryId.GetClicksMetrics, merchantId, start, end],
  [QueryId.GetPlpMetrics]: (
    merchantId: string,
    listingId: string,
    start?: number,
    end?: number,
    market?: string
  ) => [QueryId.GetPlpMetrics, merchantId, listingId, start, end, market],
  [QueryId.GetProductSpotlightMetrics]: (merchantId: string) => [
    QueryId.GetProductSpotlightMetrics,
    merchantId,
  ],
  [QueryId.GetAutoHide]: (merchantId: string) => [
    QueryId.GetAutoHide,
    merchantId,
  ],
  [QueryId.GetAutoHideExcludeProducts]: (merchantId: string) => [
    QueryId.GetAutoHideExcludeProducts,
    merchantId,
  ],
  [QueryId.GetShopifyData]: (merchantId: string) => [
    QueryId.GetShopifyData,
    merchantId,
  ],
  [QueryId.GetLooks]: (merchantId: string) => [QueryId.GetLooks, merchantId],
  [QueryId.GetShopifyUrl]: (merchantId: string) => [
    QueryId.GetShopifyUrl,
    merchantId,
  ],
  [QueryId.GetProductRecommendations]: (
    merchantId: string,
    kind: string,
    productId: string | null
  ) => [QueryId.GetProductRecommendations, merchantId, kind, productId],
  [QueryId.GetProductsByItemGroupId]: (
    merchantId: string,
    itemGroupId: string
  ) => [QueryId.GetProductsByItemGroupId, merchantId, itemGroupId],
  [QueryId.GetNumberOfProducts]: (merchantId: string) => [
    QueryId.GetNumberOfProducts,
    merchantId,
  ],
  [QueryId.GetNumberOfSalesProducts]: (merchantId: string) => [
    QueryId.GetNumberOfSalesProducts,
    merchantId,
  ],
  [QueryId.GetSCollections]: (
    merchantId: string,
    accessCategoryV3: boolean
  ) => [QueryId.GetSCollections, merchantId, accessCategoryV3],
  [QueryId.GetSCollection]: (
    merchantId: string,
    collectionId: string,
    marketGroup: string,
    market: string | null
  ) => [QueryId.GetSCollection, merchantId, collectionId, marketGroup, market],
  [QueryId.GetInboxMainProductIds]: (merchantId: string) => [
    QueryId.GetInboxMainProductIds,
    merchantId,
  ],
  [QueryId.GetAuthorizedMerchants]: () => [QueryId.GetAuthorizedMerchants],
  [QueryId.GetCollections]: (merchantId: string) => [
    QueryId.GetCollections,
    merchantId,
  ],
  [QueryId.GetLongTailCollections]: (
    marketId: string,
    merchantId: string,
    offset: number,
    limit: number,
    sortBy: string,
    sortDirection: string
  ) => [
    QueryId.GetLongTailCollections,
    marketId,
    merchantId,
    offset,
    limit,
    sortBy,
    sortDirection,
  ],
  [QueryId.GetLongTailCollectionCount]: (
    marketId: string,
    merchantId: string
  ) => [QueryId.GetLongTailCollectionCount, marketId, merchantId],
  [QueryId.GetCollection]: (merchantId: string, collectionId: string) => [
    QueryId.GetCollection,
    merchantId,
    collectionId,
  ],

  [QueryId.GetSearchStats]: (
    merchantId: string,
    period: {
      start: number;
      end: number;
    },
    maxQueries: number
  ) => [QueryId.GetSearchStats, merchantId, period, maxQueries],
  [QueryId.GetContentSearch]: (merchantId: string, localeId: string) => [
    QueryId.GetContentSearch,
    merchantId,
    localeId,
  ],
  [QueryId.GetSynonyms]: (merchantId: string, localeId: string) => [
    QueryId.GetSynonyms,
    merchantId,
    localeId,
  ],
  [QueryId.GetCentraConfiguredMarkets]: (merchantId: string) => [
    QueryId.GetCentraConfiguredMarkets,
    merchantId,
  ],
  [QueryId.GetCentraStores]: (merchantId: string) => [
    QueryId.GetCentraStores,
    merchantId,
  ],
  [QueryId.GetCategoryProducts]: (
    merchantId: string,
    categoryId: string,
    salt: string
  ) => [QueryId.GetCategoryProducts, merchantId, categoryId, salt],
  [QueryId.GetCategoryProductsOrder]: (
    merchantId: string,
    categoryId: string,
    sortBy: { field: string; order: string } | undefined,
    rankingType: string | undefined,
    salt: string
  ) => [
    QueryId.GetCategoryProductsOrder,
    merchantId,
    categoryId,
    sortBy?.field,
    sortBy?.order,
    rankingType,
    salt,
  ],
  [QueryId.GetCategoryProductOrderOverrides]: (
    merchantId: string,
    categoryId: string
  ) => [QueryId.GetCategoryProductOrderOverrides, merchantId, categoryId],
  [QueryId.GetGetCategoryPreviewProducts]: (
    merchantId: string,
    categoryId: string,
    sortBy?: { field: string; order: string }
  ) => [
    QueryId.GetGetCategoryPreviewProducts,
    merchantId,
    categoryId,
    sortBy?.field,
    sortBy?.order,
  ],
  [QueryId.GetCategoryProductIds]: (merchantId: string, categoryId: string) => [
    QueryId.GetCategoryProductIds,
    merchantId,
    categoryId,
  ],
  [QueryId.GetCategory]: (merchantId: string, categoryId: string, marketGroup: string, market: string | null) => [
    QueryId.GetCategory,
    merchantId,
    categoryId,
    marketGroup,
    market
  ],
  [QueryId.GetCollectionProducts]: (
    merchantId: string,
    collectionId: string
  ) => [QueryId.GetCollectionProducts, merchantId, collectionId],
  [QueryId.GetMerchantProducts]: (merchantId: string) => [
    QueryId.GetMerchantProducts,
    merchantId,
  ],
  [QueryId.GetCatalogProducts]: (merchantId: string, localeId: string) => [
    QueryId.GetCatalogProducts,
    merchantId,
    localeId,
  ],
  [QueryId.GetCatalogSyncStatus]: (merchantId: string) => [
    QueryId.GetCatalogSyncStatus,
    merchantId,
  ],
  [QueryId.GetPairs]: (
    merchantId: string,
    collectionId: string,
    chunkType: string
  ) => [QueryId.GetPairs, merchantId, collectionId, chunkType],
  [QueryId.GetBoostAndBuryRules]: (merchantId: string) => [
    QueryId.GetBoostAndBuryRules,
    merchantId,
  ],
  [QueryId.GetAvailableDimensionKeys]: (merchantId: string) => [
    QueryId.GetAvailableDimensionKeys,
    merchantId,
  ],
  [QueryId.GetAvailableShopifyMarkets]: (
    merchantId: string,
    adminApiUrl: string,
    adminApiKey: string
  ) => [
    QueryId.GetAvailableShopifyMarkets,
    merchantId,
    adminApiUrl,
    adminApiKey,
  ],
  [QueryId.GetAvailableShopifyMetafieldDefinitions]: (
    merchantId: string,
    adminApiUrl: string,
    adminApiKey: string
  ) => [
    QueryId.GetAvailableShopifyMetafieldDefinitions,
    merchantId,
    adminApiUrl,
    adminApiKey,
  ],
  [QueryId.GetAvailableCentraAttributes]: (
    merchantId: string,
    adminApiUrl: string,
    adminApiKey: string
  ) => [
    QueryId.GetAvailableCentraAttributes,
    merchantId,
    adminApiUrl,
    adminApiKey,
  ],
  [QueryId.ProductCatalogSearchAndFilterProvider]: (
    merchantId: string,
    searchTerm: string,
    selectedFilters: SearchFilter[],
    allowedMainProductIds: string[],
    selectedSort: PortalSortModel,
    currentView: string
  ) => [
    QueryId.ProductCatalogSearchAndFilterProvider,
    merchantId,
    searchTerm,
    selectedFilters,
    allowedMainProductIds,
    selectedSort,
    currentView,
  ],
  [QueryId.GetCatalogProductFilters]: (merchantId: string) => [
    QueryId.GetCatalogProductFilters,
    merchantId,
  ],
  [QueryId.GetCatalogProductMetadata]: (merchantId: string) => [
    QueryId.GetCatalogProductMetadata,
    merchantId,
  ],
  [QueryId.GetRuleBasedCollections]: (merchantId: string, locale: string) => [
    QueryId.GetRuleBasedCollections,
    merchantId,
    locale,
  ],
  [QueryId.GetRuleBasedCollection]: (
    merchantId: string,
    collectionId: string,
    locale: string
  ) => [QueryId.GetRuleBasedCollection, merchantId, collectionId, locale],
  [QueryId.GetCollectionByQueryId]: (merchantId: string, queryId: string) => [
    QueryId.GetCollectionByQueryId,
    merchantId,
    queryId,
  ],
  [QueryId.GetShopifyApiCredentials]: (merchantId: string) => [
    QueryId.GetShopifyApiCredentials,
    merchantId,
  ],
  [QueryId.GetCategorySearchResults]: (
    merchantId: string,
    categoryId: string
  ) => [QueryId.GetCategorySearchResults, merchantId, categoryId],
  [QueryId.GetAvailableSorts]: (
    merchantId: string,
    oosAutoHideState: boolean,
    currentView: string
  ) => [QueryId.GetAvailableSorts, merchantId, oosAutoHideState, currentView],
  [QueryId.GetIntegrations]: (merchantId: string) => [
    QueryId.GetIntegrations,
    merchantId,
  ],
  [QueryId.GetMaintenance]: () => [QueryId.GetMaintenance],
  [QueryId.GetProductSpotlighSalesData]: (
    merchantId: string,
    market: string,
    startDate: string,
    endDate: string
  ) => [
    QueryId.GetProductSpotlighSalesData,
    merchantId,
    market,
    startDate,
    endDate,
  ],
  [QueryId.GetProductClickSources]: (
    merchantId: string,
    market: string,
    startDate: string,
    endDate: string,
    selectedMainProductId: string
  ) => [
    QueryId.GetProductsSalesData,
    merchantId,
    market,
    startDate,
    endDate,
    selectedMainProductId,
  ],
  [QueryId.GetProductsSalesData]: (
    merchantId: string,
    market: string,
    startDate: string,
    endDate: string,
    limit: number,
    sortField: string,
    descending: boolean
  ) => [
    QueryId.GetProductsSalesData,
    merchantId,
    market,
    startDate,
    endDate,
    limit,
    sortField,
    descending,
  ],
  [QueryId.GetProductsClicksOrigin]: (
    merchantId: string,
    market: string,
    startDate: number,
    endDate: number
  ) => [
    QueryId.GetProductsClicksOrigin,
    merchantId,
    market,
    startDate,
    endDate,
  ],
  [QueryId.GetDiagnosticEvents]: (merchantId: string, startDate: number) => [
    QueryId.GetDiagnosticEvents,
    merchantId,
    startDate,
  ],
  [QueryId.GetAttributes]: (merchantId: string) => [
    QueryId.GetAttributes,
    merchantId,
  ],
  [QueryId.GetAttribute]: (merchantId: string, attributeId: string) => [
    QueryId.GetAttribute,
    merchantId,
    attributeId,
  ],
  [QueryId.GetSettingsAttributeMetadata]: (merchantId: string) => [
    QueryId.GetSettingsAttributeMetadata,
    merchantId,
  ],
  [QueryId.GetSettingsFilters]: (merchantId: string) => [
    QueryId.GetSettingsFilters,
    merchantId,
  ],
  [QueryId.GetSettingsFiltersValue]: (
    merchantId: string,
    attributeId: string,
    sortingOrder: FilterValuesSortingOrder,
    isHierarchical: boolean,
    withDefaultColors: boolean
  ) => [
    QueryId.GetSettingsFiltersValue,
    merchantId,
    attributeId,
    sortingOrder,
    isHierarchical,
    withDefaultColors,
  ],
  [QueryId.GetSettingsFiltersTranslations]: (merchantId: string) => [
    QueryId.GetSettingsFiltersTranslations,
    merchantId,
  ],
  [QueryId.GetSettingsUIConfig]: (merchantId: string, configType: string) => [
    QueryId.GetSettingsUIConfig,
    merchantId,
    configType,
  ],
  [QueryId.GetHasCompletedOnBoarding]: (merchantId: string) => [
    QueryId.GetHasCompletedOnBoarding,
    merchantId,
  ],
  [QueryId.GetIngestionRunLogs]: (merchantId: string) => [
    QueryId.GetIngestionRunLogs,
    merchantId,
  ],
  [QueryId.GetCentraAttributes]: (merchantId: string) => [
    QueryId.GetCentraAttributes,
    merchantId,
  ],
  [QueryId.GetCentraFieldNames]: (merchantId: string) => [
    QueryId.GetCentraFieldNames,
    merchantId,
  ],
  [QueryId.GetTrackingDiagnosticsMapping]: (merchantId: string) => [
    QueryId.GetTrackingDiagnosticsMapping,
    merchantId,
  ],
  [QueryId.GetTrackingDiagnosticEvents]: (
    merchantId: string,
    fromTimestamp: number
  ) => [QueryId.GetTrackingDiagnosticEvents, merchantId, fromTimestamp],
  [QueryId.GetTrackingDiagnosticAttributionStats]: (
    merchantId: string,
    fromTimestamp: number
  ) => [
    QueryId.GetTrackingDiagnosticAttributionStats,
    merchantId,
    fromTimestamp,
  ],
  [QueryId.GetUploadedMedia]: (merchantId: string) => [
    QueryId.GetUploadedMedia,
    merchantId,
  ],
  [QueryId.GetMarketGroups]: (merchantId: string) => [
    QueryId.GetMarketGroups,
    merchantId,
  ],
} as const;

type QueryKeyArgs = {
  [K in QueryId]: Parameters<(typeof queryKeyBuilders)[K]>;
};

export function getQueryKey<K extends QueryId>(
  queryId: K,
  ...args: QueryKeyArgs[K]
): QueryKey {
  // @ts-expect-error
  return queryKeyBuilders[queryId](...args);
}

export enum MutationId {
  UpdateAttribute = "UpdateAttribute",
  AddAttribute = "AddAttribute",
}

export const mutationKeyBuilders = {
  [MutationId.UpdateAttribute]: (merchantId: string, attributeId: number) => [
    MutationId.UpdateAttribute,
    merchantId,
    attributeId,
  ],
  [MutationId.AddAttribute]: (merchantId: string) => [
    MutationId.AddAttribute,
    merchantId,
  ],
} as const;

type MutationKeyArgs = {
  [K in MutationId]: Parameters<(typeof mutationKeyBuilders)[K]>;
};

export function getMutationKey<K extends MutationId>(
  mutationId: K,
  ...args: MutationKeyArgs[K]
): QueryKey {
  // @ts-expect-error
  return mutationKeyBuilders[mutationId](...args);
}
