import useMerchant from "./app/useMerchant";

interface UseIsDepictLiteReturn {
  isDepictLite: boolean | null;
}

export const useIsDepictLite = (): UseIsDepictLiteReturn => {
  const merchant = useMerchant();

  const isDepictLite = merchant.merchant
    ? merchant.merchant.is_depict_lite
    : null;

  return {
    isDepictLite,
  };
};
