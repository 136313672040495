import { Dropdown } from "react-bootstrap";
import { ListingSorting } from "./types";

export default function ListingTreeSortingDropdown({
  sorting,
  onSetSorting,
}: {
  sorting: ListingSorting;
  onSetSorting: (value: ListingSorting) => void;
}) {
  return (
    <Dropdown id={`dropdown-sorting`} className="text-xs">
      <Dropdown.Toggle
        variant="link"
        className="p-0"
        style={{ textDecoration: "none" }}
      >
        {sorting.field === "title" && "Alphabetical"}
        {sorting.field === "nProducts" && "Number of products"}
        {sorting.field === "updatedAt" &&
          sorting.direction === "desc" &&
          "Recent"}
        {sorting.field === "updatedAt" &&
          sorting.direction === "asc" &&
          "Oldest"}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => {
            onSetSorting({ field: "title", direction: "asc" });
          }}
        >
          Alphabetical (A-Z)
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            onSetSorting({ field: "title", direction: "desc" });
          }}
        >
          Alphabetical (Z-A)
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            onSetSorting({ field: "updatedAt", direction: "desc" });
          }}
        >
          Recent
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            onSetSorting({ field: "updatedAt", direction: "asc" });
          }}
        >
          Oldest
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            onSetSorting({ field: "nProducts", direction: "asc" });
          }}
        >
          Number of products (ascending)
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            onSetSorting({ field: "nProducts", direction: "desc" });
          }}
        >
          Number of products (descending)
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
