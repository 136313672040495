import { css } from "@emotion/react";
import { Placeholder } from "@phosphor-icons/react";
import randomColor from "randomcolor";
import { CollectionType } from "../../api/types";
import { HoverableDiv } from "../storybook/ListingTreeMenu/ListingTreeItem";
import { theme } from "../storybook/designSystemVariables";
import { metadataStyles } from "../storybook/ProductBadge/MetadataStyles";
import { useImageResize } from "../storybook/ImageResizeProvider/ImageResizeProvider";
import Logo from "../../lite/Logo";
import RuleTag from "src/assets/img/collection-rule-tag.svg";

const bestSellerColor = metadataStyles.bestseller.backgroundColor;
const newInColor = metadataStyles.new_in.backgroundColor;
const saleColor = metadataStyles.on_sale.backgroundColor;
const trendingColor = metadataStyles.trending.backgroundColor;

function getColorForSmartPickId(typeSpecificId: string | null | undefined) {
  switch (typeSpecificId) {
    case "best_seller":
      return bestSellerColor;
    case "new_in":
      return newInColor;
    case "on_sale":
      return saleColor;
    case "trending":
      return trendingColor;
    default:
      return randomColor({ seed: "", hue: "random" });
  }
}

interface PlpLogoProps {
  imageUrl?: string;
  collectionType: CollectionType;
  title: string;
  isSelected: boolean;
  typeSpecificId?: string | null;
}
export const PlpLogo = (props: PlpLogoProps) => {
  const { getImageUrl } = useImageResize();
  const { imageUrl, collectionType, title } = props;
  const color = getColorForSmartPickId(props.typeSpecificId);
  if (collectionType === "smart_pick") {
    return (
      <div
        css={css`
          display: flex;
          width: 32px;
          height: 32px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          border-radius: 4px;
          background: var(--background-warning-pressed, ${color});
          margin-right: 0.5rem;
        `}
      >
        <Logo size={16} color={theme.textIcon.inverse.default} />
      </div>
    );
  }
  if (imageUrl && collectionType === "brand") {
    return (
      <div
        className="me-2"
        css={css`
          width: 32px;
          height: 32px;
          object-fit: cover;
          background-size: cover;
          background-image: url(${imageUrl});
          border-radius: 4px;
          position: relative;
        `}
      >
        <div
          css={css`
            width: 12px;
            height: 12px;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            background: ${props.isSelected
              ? "white"
              : theme.background.base.hover};
            ${HoverableDiv}:hover & {
              background-color: #e4e4e5;
            }
            border-top-left-radius: 2px;
            border-bottom-right-radius: 4px;
            position: absolute;
            bottom: 0;
            right: 0;
          `}
        >
          <img
            alt="rule-tag"
            className="rule-tag"
            css={css`
              height: 12px;
              width: 12px;
            `}
            src={RuleTag}
          />
        </div>
      </div>
    );
  }
  if (imageUrl) {
    return (
      <div
        className="me-2"
        css={css`
          width: 32px;
          height: 32px;
          object-fit: cover;
          background-size: cover;
          background-image: url(${getImageUrl(imageUrl, 80)});
          border-radius: ${collectionType === "category" ? "4px" : "50%"};
        `}
      />
    );
  } else {
    return (
      <div
        className="me-2 d-flex justify-content-center align-items-center"
        css={css`
          width: 32px;
          height: 32px;
          background-color: ${theme.background.subtle.hover};
          border-radius: ${collectionType === "category" ||
          collectionType === "brand"
            ? "4px"
            : "50%"};
        `}
      >
        <Placeholder color={theme.textIcon.subtle.default} />
      </div>
    );
  }
};
