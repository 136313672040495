import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { CollectionType } from "src/api/types";
import { buildPathWithMerchantId, ROUTES } from "../router/Routes";
import { SidebarContext } from "./SidebarContext";
import { useProductScollectionContext } from "./ProductScollectionContext";

const ALLOWED_ROUTES_FOR_SELECT_MODE = [ROUTES.CATALOG];
export const ALLOWED_SCOLLECTION_TYPES_FOR_EDITING: CollectionType[] = [
  "category",
  "style",
  "campaign",
  "style",
];

function selectModeIsAllowed(pathname: string, merchantId?: string) {
  return ALLOWED_ROUTES_FOR_SELECT_MODE.some((route) =>
    pathname.includes(buildPathWithMerchantId(route, merchantId ?? ""))
  );
}

interface ListingEditorContextType {
  inSelectMode: boolean;

  selectedMainProductIds: Set<string>;
  setSelectedMainProductIds: (selectedMainProductIds: Set<string>) => void;

  selectedListingItemIds: Set<string>;
  indeterminateListingItemIds: Set<string>;

  disabledListingItemIds: Set<string>;

  mainProductIdsWithListings: Set<string>;
}

export const ListingEditorContext =
  createContext<ListingEditorContextType | null>(null);

export function ListingEditorProvider({
  merchantId,
  children,
}: {
  merchantId?: string;
  children: ReactNode;
}) {
  const [selectedMainProductIds, setSelectedMainProductIds] = useState<
    Set<string>
  >(new Set());

  const inSelectMode = selectedMainProductIds.size > 0;

  const { setIsCollapsed } = useContext(SidebarContext);

  const { productToScollections, productToRuleBasedScollections } =
    useProductScollectionContext();

  useEffect(() => {
    if (inSelectMode) {
      setIsCollapsed(true);
    }
  }, [inSelectMode, setIsCollapsed]);

  const onExitSelectMode = () => {
    setSelectedMainProductIds(new Set());
  };

  const location = useLocation();
  useEffect(() => {
    if (inSelectMode && !selectModeIsAllowed(location.pathname, merchantId)) {
      onExitSelectMode();
    }
  }, [location.pathname, merchantId, inSelectMode]);

  useEffect(() => {
    onExitSelectMode();
  }, [merchantId]);

  const selectedListingItemIds = new Set(
    Array.from(selectedMainProductIds).flatMap(
      (mainProductId) => productToScollections.get(mainProductId) ?? []
    )
  );

  const disabledListingItemIds = new Set(
    Array.from(selectedMainProductIds).flatMap(
      (mainProductId) => productToRuleBasedScollections.get(mainProductId) ?? []
    )
  );

  const indeterminateListingItemIds = new Set(
    Array.from(selectedListingItemIds).filter((id) =>
      Array.from(selectedMainProductIds).some(
        (mainProductId) =>
          !(productToScollections.get(mainProductId) ?? []).includes(id)
      )
    )
  );

  const mainProductIdsWithListings = new Set(
    Array.from(productToScollections.entries())
      .filter(([_, scollectionIds]) => scollectionIds.length > 0)
      .map(([mainProductId]) => mainProductId)
  );

  return (
    <ListingEditorContext.Provider
      value={{
        inSelectMode,
        selectedMainProductIds,
        setSelectedMainProductIds,
        indeterminateListingItemIds,
        selectedListingItemIds,
        mainProductIdsWithListings,
        disabledListingItemIds,
      }}
    >
      {children}
    </ListingEditorContext.Provider>
  );
}

export function useListingEditorContext(merchantId?: string) {
  const context = useContext(ListingEditorContext);
  if (!context) {
    throw new Error(
      "useListingEditorContext must be used within a ListingEditorProvider"
    );
  }

  const {
    inSelectMode,
    selectedMainProductIds,
    setSelectedMainProductIds,
    indeterminateListingItemIds,
    selectedListingItemIds,
    mainProductIdsWithListings,
    disabledListingItemIds,
  } = context;

  return {
    inSelectMode,
    selectedMainProductIds,
    setSelectedMainProductIds,
    indeterminateListingItemIds,
    selectedListingItemIds,
    mainProductIdsWithListings,
    disabledListingItemIds,
  };
}
