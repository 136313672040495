import ListingTreeTypeButton, { getFolderName } from "./ListingTreeTypeButton";
import { Badge, Button } from "react-bootstrap";
import { X } from "@phosphor-icons/react";
import { ItemTypes } from "./ListingTreeItem";
import { useDragLayer } from "react-dnd";
import { CollectionType } from "../../../api/types";

export default function ListingTreeFilterButtons({
  filteredType,
  onSetFilteredType,
  availableFilterTypes,
  onSetType,
}: {
  filteredType: CollectionType | null;
  onSetFilteredType: (value: CollectionType | null) => void;
  availableFilterTypes: CollectionType[];
  onSetType: (value: CollectionType, listingItemId: string) => void;
}) {
  const { isDragging } = useDragLayer((monitor) => ({
    isDragging:
      monitor.isDragging() && monitor.getItemType() === ItemTypes.LISTING_ITEM,
  }));

  return (
    <div>
      {filteredType === null || isDragging ? (
        <>
          {availableFilterTypes
            .sort((a, b) => {
              if (a === "category") return -1; // "Category" comes first
              if (b === "category") return 1; // "Category" comes first
              if (a === "smart_pick") return -1; // "Dynamic Status" comes second
              if (b === "smart_pick") return 1; // "Dynamic Status" comes second
              return a.localeCompare(b); // Sort the other types alphabetically
            })
            .map((type) => (
              <ListingTreeTypeButton
                key={type}
                onSetType={onSetType}
                type={type}
                filteredType={filteredType}
                onSetFilteredType={onSetFilteredType}
              />
            ))}
        </>
      ) : (
        <>
          <Button
            variant="outline-secondary"
            className="p-1 m-0 rounded-circle me-1"
            onClick={() => {
              onSetFilteredType(null);
            }}
            style={{
              width: "24px",
              height: "24px",
            }}
          >
            <X />
          </Button>
          <Badge
            bg="primary"
            className="p-1 m-0 rounded-pill me-1 text-white"
            style={{ fontSize: "10px", height: "24px", lineHeight: "16px" }}
          >
            {getFolderName(filteredType)}
          </Badge>
        </>
      )}
    </div>
  );
}
