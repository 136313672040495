import React, { createContext, useContext, useState } from "react";

interface SyncBannerContextType {
  showSyncBanner: boolean;
  setShowSyncBanner: React.Dispatch<React.SetStateAction<boolean>>;
}

const SyncBannerContext = createContext<SyncBannerContextType | undefined>(
  undefined
);

export function SyncBannerProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [showSyncBanner, setShowSyncBanner] = useState(false);

  return (
    <SyncBannerContext.Provider value={{ showSyncBanner, setShowSyncBanner }}>
      {children}
    </SyncBannerContext.Provider>
  );
}

export const useSyncBanner = (): SyncBannerContextType => {
  const context = useContext(SyncBannerContext);
  if (context === undefined) {
    throw new Error("useSyncBanner must be used within a SyncBannerProvider");
  }
  return context;
};
