import React, { createContext, useCallback, useMemo, useState } from "react";

export const TopNavContext = createContext({
  isTopNavOpen: false,
  topNavHeight: 0,
});

export const useTopNavContext = () => React.useContext(TopNavContext);

export const TopNavDispatchContext = createContext({
  setIsTopNavOpen: (isOpen: boolean) => {},
  setTopNavHeight: (height: number) => {},
});

interface TopNavProviderProps {
  children: React.ReactNode;
}
export const TopNavProvider = (props: TopNavProviderProps) => {
  const [isTopNavOpen, _setIsTopNavOpen] = useState(false);
  const [topNavHeight, setTopNavHeight] = useState(0);

  const setIsTopNavOpen = useCallback((isOpen: boolean) => {
    _setIsTopNavOpen(isOpen);
  }, []);

  const dispatchValue = useMemo(
    () => ({
      setIsTopNavOpen,
      setTopNavHeight,
    }),
    [setIsTopNavOpen, setTopNavHeight]
  );

  const contextValue = useMemo(
    () => ({
      isTopNavOpen,
      topNavHeight,
    }),
    [isTopNavOpen, topNavHeight]
  );

  return (
    <TopNavDispatchContext.Provider value={dispatchValue}>
      <TopNavContext.Provider value={contextValue}>
        {props.children}
      </TopNavContext.Provider>
    </TopNavDispatchContext.Provider>
  );
};
