import { css } from "@emotion/react";
import {
  Book,
  Dress,
  Fire,
  Gauge,
  Gear,
  Heart,
  Lock,
  MagnifyingGlass,
} from "@phosphor-icons/react";
import React, { useContext } from "react";
import { Collapse } from "react-bootstrap";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { NavLink } from "react-router-dom";
import { ChevronDown, ChevronUp } from "src/components/linearicons";
import { useMerchant } from "src/helpers/hooks/app/useMerchant";
import { useIsDepictLite } from "src/helpers/hooks/useIsDepictLite";
import { ROUTES, buildPathWithMerchantId } from "src/router/Routes";
import { SidebarNavProps } from "src/types/components";
import { SidebarContext } from "../../contexts/SidebarContext";
import { shopifyTokenFromURL } from "../../helpers/misc/shopifyToken";
import ListingTree from "../ListingTree/ListingTree";
import { ShopifyIcon } from "../ShopifyIcon";
import MerchantSelector from "../mix/MerchantSelector";
import { LabelBadge } from "../storybook/LabelBadge/LableBadge";
import { theme } from "../storybook/designSystemVariables";
import Logo from "../../lite/Logo";

export const SmallScreenCutOff = 1000;

const LinkWrapper = (props: {
  path: string;
  state: "loading" | "locked" | "unlocked";
  children?: React.ReactNode;
  newTab?: boolean;
}) => {
  const locked = props.state === "locked";
  const loading = props.state === "loading";

  const navCss = css`
    padding: 0.5rem;
    display: flex;
    align-items: center;
    border-radius: 8px;
    text-decoration: none !important;
    color: #868689;
    font-weight: bold;
    &:hover {
      background-color: ${theme.background.subtle.hover};
    }
    &[aria-current="page"] {
      background-color: white;
      color: #0f0f0f;
    }
  `;

  if (locked) {
    return (
      <div css={navCss} className="text-muted">
        {props.children}
      </div>
    );
  }

  if (loading) {
    return (
      <div css={navCss} className="text-muted">
        {props.children}
      </div>
    );
  }

  return (
    <NavLink
      css={navCss}
      to={props.path}
      target={props.newTab ? "_blank" : "_self"}
    >
      {props.children}
    </NavLink>
  );
};

type State = "loading" | "locked" | "unlocked";

interface NavItemWrapperProps {
  children: React.ReactNode;
  state: State;
  path: string;
}
const NavItemWrapper = (props: NavItemWrapperProps) => {
  const { children, state, path } = props;

  return (
    <LinkWrapper path={path} state={state}>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        `}
      >
        {children}
      </div>
    </LinkWrapper>
  );
};

interface LockComponentProps {
  state: State;
}
const LockComponent = (props: LockComponentProps) => {
  const { state } = props;

  if (state === "locked") {
    return <Lock size={16} />;
  }

  return null;
};

const SidebarNav = (props: SidebarNavProps) => {
  const { inboxCount, user } = props;
  const { merchantId, merchant, merchantOptions } = useMerchant();

  const { isDepictLite } = useIsDepictLite();

  const depictLiteMenuStatus =
    isDepictLite === null ? "loading" : isDepictLite ? "locked" : "unlocked";

  const menuItemsStatus = {
    dashboard: "unlocked",
    search: depictLiteMenuStatus,
    plp: "unlocked",
    recommendations: depictLiteMenuStatus,
    boost_and_bury: "unlocked",
    getting_started: "unlocked",
    getting_started_page: "unlocked",
    products: "unlocked",
    settings: "unlocked",
  } as const;

  const productStatus = merchant?.product_status;

  const { isCollapsed, setIsCollapsed } = useContext(SidebarContext);

  const getPath = (path: ROUTES) => {
    if (!merchantId) return path;
    return buildPathWithMerchantId(path, merchantId);
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        flex: 0 0 420px;
        overflow-y: auto;
        @media (max-width: ${SmallScreenCutOff}px) {
          flex: 0 0 100vw;
        }
      `}
    >
      {shopifyTokenFromURL && (
        <button
          css={css`
            display: flex;
            align-items: flex-start;
            justify-content: center;
            border: none;
            gap: 8px;
            padding: 12px 16px;
            border-radius: 8px;
            box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.24);
            margin-top: 0.5rem;
            margin-left: 0.5rem;
            margin-right: 0.5rem;
            background: #fff;
            &:hover {
              background: #fafafc;
            }
            &:active {
              background: #e4e4e5;
            }
          `}
          onClick={() =>
            window.parent?.postMessage({ type: "portal_exit_to_shopify" }, "*")
          }
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15">
            <path
              d="M5.661 12.255L1.723 8.318c-.061-.061-.11-.133-.143-.213s-.05-.165-.05-.252.017-.172.05-.252.082-.152.143-.213L5.661 3.45c.123-.123.29-.193.465-.193s.342.069.465.193.193.29.193.465-.069.342-.193.465L3.773 7.197h8.039c.174 0 .341.069.464.192s.192.29.192.464-.069.341-.192.464-.29.192-.464.192H3.773l2.818 2.817c.123.123.193.29.193.465s-.069.341-.193.465-.29.193-.465.193-.342-.069-.465-.193v-.001z"
              fill="#0f0f0f"
            />
          </svg>
          <ShopifyIcon />
        </button>
      )}
      {merchant?.platform === "shopify" && !merchant.is_depict_lite && (
        <div className="m-2 p-2 rounded border border-secondary text-sm">
          <NavItemWrapper
            path={getPath(ROUTES.GETTING_STARTED_PAGE)}
            state={menuItemsStatus["getting_started_page"]}
          >
            <div
              css={css`
                display: flex;
                gap: 4px;
              `}
            >
              <span className="emoji">&#x1F680;</span>
              Getting started
            </div>

            <LockComponent state={menuItemsStatus["getting_started_page"]} />
          </NavItemWrapper>
        </div>
      )}
      <div className="m-2 p-2 rounded border border-secondary text-sm">
        <div
          className="p-2 d-flex align-items-center rounded w-100"
          css={css`
            color: #868689;
            font-weight: bold;
            cursor: pointer;
            &:hover {
              background-color: ${theme.background.subtle.hover};
            }
            &[aria-current="page"] {
              background-color: white;
              color: #0f0f0f;
            }
          `}
          onClick={() => {
            setIsCollapsed(!isCollapsed);
          }}
        >
          <div className="me-2 d-flex align-items-center justify-content-center">
            <Logo size={12}/>
          </div>
          Depict
          <div className="ms-auto me-1">
            {isCollapsed ? <ChevronDown /> : <ChevronUp />}
          </div>
        </div>
        <Collapse in={!isCollapsed}>
          <div>
            <NavItemWrapper
              path={getPath(ROUTES.DASHBOARD)}
              state={menuItemsStatus["dashboard"]}
            >
              <div>
                <Gauge size={16} className="me-2" />
                Dashboard
              </div>
              <LockComponent state={menuItemsStatus["dashboard"]} />
            </NavItemWrapper>
            {productStatus?.recommendations_state !== "inactive" && (
              <NavItemWrapper
                path={getPath(ROUTES.RECOMMENDATIONS)}
                state={menuItemsStatus["recommendations"]}
              >
                <div>
                  <Heart size={16} className="me-2" />
                  Recommend
                </div>
                <LockComponent state={menuItemsStatus["recommendations"]} />
              </NavItemWrapper>
            )}
            {(productStatus?.search_state !== "inactive" ||
              user?.is_superuser) && (
              <NavItemWrapper
                path={getPath(ROUTES.SEARCH)}
                state={menuItemsStatus["search"]}
              >
                <div>
                  <MagnifyingGlass size={16} className="me-2" />
                  Search
                </div>
                <LockComponent state={menuItemsStatus["search"]} />
              </NavItemWrapper>
            )}
            {(productStatus?.plp_state !== "inactive" ||
              productStatus?.search_state !== "inactive") && (
              <NavItemWrapper
                path={getPath(ROUTES.BOOST_AND_BURY)}
                state={menuItemsStatus["boost_and_bury"]}
              >
                <div>
                  <Fire size={16} className="me-2" />
                  Boost & bury
                </div>
                <LockComponent state={menuItemsStatus["boost_and_bury"]} />
              </NavItemWrapper>
            )}
          </div>
        </Collapse>
        {(productStatus?.plp_state !== "inactive" ||
          productStatus?.search_state !== "inactive") && (
          <NavItemWrapper
            path={
              getPath(ROUTES.CATALOG) +
              (inboxCount && inboxCount > 0 ? "?view=inbox" : "")
            }
            state={menuItemsStatus["products"]}
          >
            <div className="d-flex align-items-center justify-content-between w-100">
              <div>
                <Book size={16} className="me-2" />
                Products
              </div>
              <div
                css={css`
                  display: flex;
                  gap: 0.25rem;
                `}
              >
                {inboxCount !== null && inboxCount > 0 && (
                  <LabelBadge background="accent">{inboxCount}</LabelBadge>
                )}
              </div>
            </div>
            <LockComponent state={menuItemsStatus["products"]} />
          </NavItemWrapper>
        )}
      </div>
      {(productStatus?.plp_state === "live" ||
        productStatus?.plp_state === "in_development") &&
        merchant && (
          <DndProvider backend={HTML5Backend}>
            <ListingTree merchant={merchant} />
          </DndProvider>
        )}
      {(!productStatus?.plp_state ||
        productStatus?.plp_state === "inactive" ||
        productStatus?.plp_state === "legacy") && (
        <div className="flex-grow-1" />
      )}
      <div className="m-2 mt-0 p-2 rounded border border-secondary text-sm">
        {merchantOptions.length > 1 && (
          <MerchantSelector selected={merchantId ?? undefined} />
        )}
        <NavItemWrapper
          path={getPath(ROUTES.SETTINGS)}
          state={menuItemsStatus["settings"]}
        >
          <Gear size={16} weight="bold" className="me-2" />
          <span
            css={css`
              flex-grow: 1;
            `}
          >
            Settings
          </span>
        </NavItemWrapper>
      </div>
    </div>
  );
};

export default React.memo(SidebarNav);
