import { Warning } from "@phosphor-icons/react";
import { theme } from "../../designSystemVariables";
import Body from "../Body/Body";

interface WarningAlertProps {
  title?: string;
  message: string | JSX.Element;
}
export const WarningAlert = (props: WarningAlertProps) => {
  return (
    <Body
      backgroundColor={theme.background.warning.default}
      titleColor={theme.textIcon.base.default}
      title={props.title || "Warning"}
      message={props.message}
      messageColor={theme.textIcon.base.default}
      icon={<Warning size={24} color={theme.textIcon.warning.default} />}
    />
  );
};

export default WarningAlert;
