import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Select, { SingleValue, StylesConfig } from "react-select";
import { Merchant } from "src/api/types";
import { useAuthorizedMerchantsContext } from "src/helpers/hooks/app/useAuthorizedMerchantsContext";
import { useMerchant } from "src/helpers/hooks/app/useMerchant";
import usePathContext from "src/helpers/hooks/app/usePathContext";
import { getPathOnMerchantChange } from "src/router/Routes";
import { MerchantSelectorProps } from "src/types/components";
import { theme } from "../storybook/designSystemVariables";

const customStyles: StylesConfig = {
  control: (provided: any) => ({
    ...provided,
    background: "transparent",
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: theme.textIcon.base.default,
    textTransform: "capitalize",
    marginLeft: 0,
  }),
  option: (provided, state) => {
    const color = state.isSelected ? "white" : "black";

    return {
      ...provided,
      color,
      textTransform: "capitalize",
      cursor: "pointer",
    };
  },
  input: (provided) => ({
    ...provided,
    color: theme.textIcon.base.default,
  }),
  container: (provided) => ({
    ...provided,
    marginBottom: "8px",
  }),
};

type NewValue = SingleValue<{
  label: string | undefined;
  value: string | undefined;
}>;

const getMerchantLabel = (merchant: Merchant, authorizedMerchants: Merchant[]) => {
  const merchantsWithSameName = authorizedMerchants.filter((m: Merchant) => m.display_name === merchant.display_name);
  if (merchantsWithSameName.length > 1) {
    return `${merchant.display_name} (${merchant.id})`;
  }
  return merchant.display_name;
}

const MerchantSelector = (props: MerchantSelectorProps) => {
  const { merchantId: selected } = useMerchant();
  const { authorizedMerchants } = useAuthorizedMerchantsContext();

  const navigate = useNavigate();
  const { path } = usePathContext();

  const selectNewMerchant = (merch: NewValue) => {
    if (selected === merch?.value) {
      return;
    }

    let newMerchant: Merchant | undefined = authorizedMerchants?.find(
      (m) => m.id === merch?.value
    );

    if (newMerchant && path) {
      const redirectPath = getPathOnMerchantChange(path, newMerchant);
      navigate(redirectPath);
    }
  };

  const currentLabel = useMemo(() => {
    const merch: Merchant | undefined = authorizedMerchants?.find(
      (m) => m.id === selected
    );
    if (!merch) {
      return "";
    }
    return getMerchantLabel(merch, authorizedMerchants as Merchant[]);
  }, [authorizedMerchants, selected]);

  return (
    <div
      className="depict--MerchantsSelect"
      style={{ width: "100%" }}
      data-tc="merchant-select"
    >
      {authorizedMerchants && authorizedMerchants.length === 1 && (
        <h2>{authorizedMerchants[0].display_name}</h2>
      )}
      {authorizedMerchants && authorizedMerchants.length > 1 && (
        <Select
          menuPlacement="top"
          theme={(theme) => ({
            ...theme,
            borderRadius: 4,
            colors: {
              ...theme.colors,
              primary25: "#F5F5F5",
              primary: "black",
            },
          })}
          styles={customStyles}
          onChange={(newValue) => selectNewMerchant(newValue as NewValue)}
          value={{
            value: selected,
            label: currentLabel,
          }}
          options={[
            {
              label: "Merchants",
              options: authorizedMerchants?.map((merchant: Merchant) => {
                return {
                  value: merchant.id,
                  // If there are multiple merchants with the same display name, append the id to the label
                  label: getMerchantLabel(merchant, authorizedMerchants),
                };
              }),
            },
          ]}
        />
      )}
    </div>
  );
};

export default React.memo(MerchantSelector);
