import { useContext, useEffect } from "react";
import { AuthorizedMerchantsContext } from "src/contexts/AuthorizedMerchants.context";
import { Merchant } from "src/api/types";
import { LEGACY_MERCHANTS } from "../../../constants/legacyMerchants";
import { useParams } from "react-router-dom";

type UseOptionalMerchantReturn =
  | { loading: true }
  | { loading: false; merchant: undefined }
  | {
      loading: false;
      merchant: Merchant;
      merchantOptions: Merchant[];
    };

/**
 * useOptionalMerchant is a React hook that provides a convenient way to access the
 * MerchantContext. This differs from the useMerchant hook in that it does not throw an error
 * if the merchant is not found. (Useful if you don't know if PortalRouter has redirected yet)
 */
export function useOptionalMerchant(): UseOptionalMerchantReturn {
  const { merchantId: merchantIdFromParams } = useParams();
  const authorizedMerchantsContext = useContext(AuthorizedMerchantsContext);

  useEffect(() => {
    if (
      merchantIdFromParams &&
      LEGACY_MERCHANTS.includes(merchantIdFromParams) &&
      window.location.hostname !== "localhost"
    ) {
      // Route to legacy portal at portal.depict.ai
      window.location.href = `https://portal.depict.ai/`;
    }
  }, [merchantIdFromParams]);

  if (!authorizedMerchantsContext) {
    throw new Error("No AuthorizedMerchantsContext found");
  }

  const { authorizedMerchants, loading } = authorizedMerchantsContext;

  if (loading) {
    return { loading: true };
  }

  if (!authorizedMerchants) {
    throw new Error("authorizedMerchantsContext is not set");
  }

  if (authorizedMerchants.length === 0) {
    return { loading: false, merchant: undefined };
  }

  if (!merchantIdFromParams) {
    const firstInstalledMerchant = authorizedMerchants.find(
      (merchant) => merchant.shopify_app_installed
    );

    return {
      loading,
      merchant: firstInstalledMerchant,
      merchantOptions: authorizedMerchants,
    };
  }

  const merchant = authorizedMerchants.find(
    (merchant) => merchant.id === merchantIdFromParams
  );

  if (!merchant) {
    throw new Error(
      "merchantId parameter does not match an authorized merchant"
    );
  }

  return {
    merchant: merchant,
    merchantOptions: authorizedMerchants,
    loading,
  } satisfies UseOptionalMerchantReturn;
}

export default useOptionalMerchant;
